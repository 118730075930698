<template>
  <div style="margin:1em">

    <p style="font-size: 1.2em;" v-html="replaceLineBreaks(notes)"></p>

    <v-btn small color="primary" @click="addItem">
      <v-icon>mdi-plus</v-icon>
      Add item
    </v-btn>

    <v-container>
      <v-row v-for="item in items" :key="item.id" style="font-size: 1.2em;">
        <v-col cols="auto" style="font-weight: bold;" class="clickable">
          <span @click="editItem(item)">{{ item.date  }}</span>
        </v-col>
         <v-col cols="auto" class="clickable">
          <span @click="editItem(item)">{{ item.actor  }}</span>
        </v-col>
         <v-col>
          <p v-html="replaceLineBreaks(item.notes)"></p>
        </v-col>
      </v-row>
    </v-container>

    <dlg-activity-item ref="dlgEditItem" :db="db"></dlg-activity-item>

  </div>
</template>

<script lang="ts">

import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import * as data from '../types'
import { DbVendor } from '../DbVendor'
import DlgActivityItem from '../dialogs/DlgActivityItem.vue'

@Component({
  components: {
    DlgActivityItem,
  },
})
export default class Activity extends Vue {

  @Prop(Object) activity!: data.ActivityRow
  @Prop(Object) db!: DbVendor

  notes = ''
  items: data.ActivityItemRow[] = []

  mounted(): void {
    this.onLoad()
  }

  @Watch('activity')
  watch_Activity(): void {
    this.onLoad()
  }

  async onLoad(): Promise<void> {
    this.notes = await this.db.activity_notes(this.activity.id)
    this.items = await this.db.activity_items(this.activity.id)
  }

  replaceLineBreaks(str: string): string {
    return str.replaceAll('\n', '<br>')
  }

  async addItem(): Promise<void> {
    const dlgEditItem = this.$refs.dlgEditItem as DlgActivityItem
    if (await dlgEditItem.add_new(this.activity.id)) {
      this.onLoad()
    }
  }

  async editItem(item: data.ActivityItemRow): Promise<void> {
    const dlgEditItem = this.$refs.dlgEditItem as DlgActivityItem
    if (await dlgEditItem.edit(item.id)) {
      this.onLoad()
    }
  }

}

</script>

