<template>

  <dlg-obj typeName="License"
           :defaultWidth="620"
           :getCurrentObject="getCurrentObject"
           :saveObject="saveObject"
           :isValid="isValid"
           v-slot="{obj}"
           @focus="onFocus"
           @object="onObjectSet"
           ref="theDialog">

    <v-container>

      <v-row>
        <v-col cols="auto">
          <v-combobox ref="txtFocusStart" label="License Number" v-model="obj.lic_number" :items="freeLicNumbers" style="max-width:6em" hide-details></v-combobox>
        </v-col>
        <v-col cols="auto">
          <v-select     label="App"     v-model="obj.app_id" :items="apps" style="max-width:9em" hide-details></v-select>
        </v-col>
        <v-col cols="auto">
          <v-select     label="Version" v-model="obj.app_version_id" :items="app_versions" :clearable="obj.app_version_id !== null" style="max-width:6em" hide-details></v-select>
        </v-col>
        <v-col cols="auto">
          <v-select     label="Usage Type" v-model="obj.usage_type" :items="db.enum_usagetype()" style="max-width:8em" hide-details></v-select>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-autocomplete label="Customer" v-model="obj.customer_id" :items="customers" clearable hide-details></v-autocomplete>
        </v-col>
        <v-col>
          <v-select       label="Contact" v-model="obj.contact_id"   :items="contacts" clearable hide-details></v-select>
          <!-- <p>{{obj.address_id !== null ? obj.address_id: 'null' }}</p> -->
        </v-col>

      </v-row>

      <v-row dense>
        <v-col>

          <v-btn-toggle v-model="obj.lic_type" dense class="mt-3">
            <v-btn v-for="type in db.enum_lictype()" :key="type" :value="type">
              {{ type === obj.lic_type ? '\u2713 ' + type : type }}
            </v-btn>
          </v-btn-toggle>

        </v-col>

        <v-col>
          <v-text-field label="Features" v-model="obj.features" class="ml-3" hide-details style="max-width:5em"></v-text-field>
        </v-col>

      </v-row>

      <v-row dense>
        <v-col>
          <edit-date label="Created" v-model="obj.created_at"></edit-date>
        </v-col>
        <v-col>
          <edit-date label="Valid Until" v-model="obj.valid_until"></edit-date>
        </v-col>
        <v-col>
          <edit-date label="Updates Until" v-model="obj.updates_until"></edit-date>
        </v-col>
      </v-row>

      <v-row>
        <v-col>

          <v-btn-toggle v-model="obj.academic" dense>
            <v-btn v-for="type in db.enum_academic()" :key="type" :value="type">
              {{ type === obj.academic ? '\u2713 ' + type : type }}
            </v-btn>
          </v-btn-toggle>

        </v-col>
      </v-row>

      <v-row dense>
        <v-col>

          <v-btn-toggle v-model="obj.protection" dense class="mt-1">
            <v-btn v-for="type in db.enum_protect()" :key="type" :value="type">
              {{ type === obj.protection ? '\u2713 ' + type : type }}
            </v-btn>
          </v-btn-toggle>

        </v-col>
      </v-row>

      <v-row dense v-if="obj.protection === 'Computer' || obj.protection === 'Dongle'">
        <v-col>
          <v-text-field label="Hardware ID" v-model="obj.hardware_id" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row dense v-if="obj.protection === 'Network'">
        <v-col cols="auto">
          <v-text-field type="number" label="Seats" v-model.number="obj.seats" hide-details  style="max-width:5em" ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-checkbox   label="Remote only (no user interface)" v-model="obj.remote_only" class="ml-3" hide-details></v-checkbox>
        </v-col>
      </v-row>

      <v-row dense v-if="obj.protection === 'Network'">
        <v-col>
          <v-text-field label="Network License Servers" v-model="obj.servers" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row >
        <v-col>
          <v-text-field label="Remark visible to user" v-model="obj.remark" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="auto">
          <v-checkbox   label="Disabled" v-model="obj.disabled" class="mr-4" hide-details></v-checkbox>
        </v-col>
        <v-col>
          <v-text-field :disabled="!obj.disabled" label="Disabled Reason" v-model="obj.disabled_note" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-textarea   label="Notes"    v-model="obj.notes" hide-details :rows="4"></v-textarea>
        </v-col>
      </v-row>

    </v-container>

    <dlg-customer ref="dlgEditCustomer" :db="db"></dlg-customer>
    <dlg-contact  ref="dlgEditContact"  :db="db"></dlg-contact>

  </dlg-obj>

</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DbVendor } from '../DbVendor'
import DlgObj from './DlgObj.vue'
import DlgCustomer from './DlgCustomer.vue'
import DlgContact from './DlgContact.vue'
import EditDate from '../components/EditDate.vue'
import * as data from '../types'

interface SelectEntry {
  text: string
  value: number | null
}

@Component({
  components: {
    DlgObj,
    DlgCustomer,
    DlgContact,
    EditDate,
  },
})
export default class DlgLicense extends Vue {

  @Prop(Object) db!: DbVendor

  theObj: data.License = data.getDefaultLicense()
  theContacts: data.ContactRow[] = []

  onObjectSet(obj: data.License): void {
    this.theObj = obj
    this.updateContactsForCustomer()
  }

  @Watch('theObj.customer_id')
  watch_ObjCustomer(newVal: number | null): void {
    // console.info('watch_ObjCustomer: ' + this.theObj.customer_id)
    this.updateContactsForCustomer()
    if (newVal === -1) {
      this.addCustomer().then((idOrNull) => {
        if (idOrNull === null) {
          this.theObj.customer_id = null
          console.info('New customer failed!')
        }
        else {
          this.theObj.customer_id = idOrNull
          console.info('New customer ok: ' + idOrNull)
        }
      })
    }
  }

  async updateContactsForCustomer(): Promise<void> {
    const customer_id = this.theObj.customer_id
    if (customer_id !== null) {
      this.theContacts = await this.db.customer_contacts(customer_id)
    }
    else {
      this.theContacts = []
    }
    const contact_id = this.theObj.contact_id
    if (contact_id !== null && !this.theContacts.some((c) => c.id === contact_id)) {
      this.theObj.contact_id = null
    }
  }

  @Watch('theObj.contact_id')
  watch_ObjContact(newVal: number | null): void {
    if (newVal === -1) {
      this.addContact().then((idOrNull) => {
        if (idOrNull === null) {
          this.theObj.contact_id = null
          console.info('New contact failed!')
        }
        else {
          this.theObj.contact_id = idOrNull
          console.info('New contact ok: ' + idOrNull)
        }
      })
    }
  }

  async addContact(): Promise<number | null> {
    if (this.theObj.customer_id === null) { return null }
    const dlgEditContact = this.$refs.dlgEditContact as DlgContact
    const idOrNull = await dlgEditContact.add_new(this.theObj.customer_id)
    if (idOrNull !== null) {
      this.updateContactsForCustomer()
    }
    return idOrNull
  }

  async addCustomer(): Promise<number | null> {
    const dlgEditCustomer = this.$refs.dlgEditCustomer as DlgCustomer
    return await dlgEditCustomer.add_new()
  }

  get apps(): SelectEntry[] {
    const values = this.db.all_apps().map((app) => {
      return {
        text: app.short_name,
        value: app.id,
      }
    })
    return values
  }

  get app_versions(): SelectEntry[] {
    const values: SelectEntry[] = this.db.versions_for_app(this.theObj.app_id).map((appVer) => {
      return {
        text: appVer.ver,
        value: appVer.id,
      }
    })
    values.sort((a, b) => {
      return a.text.localeCompare(b.text);
    })
    const none: SelectEntry = {
      text: 'Any',
      value: null,
    }
    values.unshift(none)
    return values
  }

  get customers(): SelectEntry[] {
    const values: SelectEntry[] = this.db.customers.map((cust) => {
      return {
        text: cust.short_name,
        value: cust.id,
      }
    })
    values.sort((a, b) => {
      return a.text.localeCompare(b.text);
    })
    const add: SelectEntry = {
      text: 'Add new...',
      value: -1,
    }
    values.unshift(add)
    return values
  }

  get contacts(): SelectEntry[] {
    const values: SelectEntry[] = this.theContacts.map((contact) => {
      return {
        text: contact.last_name + ', ' + contact.first_name,
        value: contact.id,
      }
    })
    values.sort((a, b) => {
      return a.text.localeCompare(b.text);
    })

    if (this.theObj.customer_id !== null) {
      const add: SelectEntry = {
        text: 'Add new...',
        value: -1,
      }
      values.unshift(add)
    }
    return values
  }

  isValid(obj: data.License): boolean {

    const nonZeroNum = (x: number | null) => {
      if (x === undefined) { return false }
      if (x === null) { return false }
      if (x === 0) { return false }
      if (x < 0) { return false }
      return Number.isInteger(x)
    }

    const nonEmptyStr = (x: string | null) => {
      if (x === undefined) { return false }
      if (x === null) { return false }
      if (x.trim() === '') { return false }
      return true
    }

    const needHardwareID = obj.protection === 'Dongle' || obj.protection === 'Computer'
    const network = obj.protection === 'Network'

    return nonZeroNum(obj.customer_id) &&
           nonZeroNum(obj.contact_id) &&
           nonEmptyStr(obj.lic_number) &&
           nonEmptyStr(obj.usage_type) &&
           nonEmptyStr(obj.lic_type) &&
           nonEmptyStr(obj.protection) &&
           nonEmptyStr(obj.academic) &&
           (nonEmptyStr(obj.hardware_id) || !needHardwareID) &&
           (nonZeroNum(obj.seats) || !network)
  }

  getCurrentObject(id: number): Promise<data.License> {
    return this.db.license(id)
  }

  saveObject(obj: data.License, is_new: boolean, objOriginal: data.License): Promise<number | null> {
    return this.db.license_write(obj, is_new, objOriginal)
  }

  @Watch('theObj.protection')
  watch_ObjProtection(newVal: string | null): void {
    if (newVal === 'Network') {
      if (this.theObj.seats === 0) {
        this.theObj.seats = 5
      }
      if (this.theObj.servers.trim() === '') {
        this.theObj.servers = 'inctrl-simba.de license-simba.ifak.eu inctrl-simba.ca'
      }
    }
  }

  onFocus(): void {
    const element = this.$refs.txtFocusStart as HTMLElement
    if (element) {
      element.focus()
    }
  }

  add_new(customer?: data.CustomerRow, contact?: data.ContactRow): Promise<number | null> {
    const dlg = this.$refs.theDialog as DlgObj
    const newObj = data.getDefaultLicense()

    if (contact !== undefined) {
      newObj.customer_id = contact.customer_id
      newObj.contact_id  = contact.id
    }
    else if (customer !== undefined) {
      newObj.customer_id = customer.id
      newObj.contact_id  = null
    }

    newObj.lic_number = this.freeLicNumbers.length > 0 ? this.freeLicNumbers[this.freeLicNumbers.length - 1] : ''
    return dlg.add_new(newObj)
  }

  edit(id: number): Promise<boolean> {
    const dlg = this.$refs.theDialog as DlgObj
    return dlg.edit(id, data.getDefaultLicense())
  }

  get freeLicNumbers(): string[] {

    const currentAppID = this.theObj.app_id

    const set = new Set<number>()
    for (const lic of this.db.licenses) {
      if (lic.app_id === currentAppID) {
        const num = parseInt(lic.lic_number)
        set.add(num)
      }
    }

    const findFirstUsed = (startIdx: number | null) => {
      if (startIdx === null) { return null }
      for (let i = startIdx; i < 5000; ++i) {
        if (set.has(i)) {
          return i
        }
      }
      return null
    }

    const findFirstUnused = (startIdx: number | null) => {
      if (startIdx === null) { return null }
      for (let i = startIdx; i < 5000; ++i) {
        if (!set.has(i)) {
          return i
        }
      }
      return null
    }

    let allUnusedStarts = []
    let firstUsed: number | null = 0
    while (firstUsed !== null) {
      firstUsed = findFirstUsed(firstUsed)
      let firstUnused = findFirstUnused(firstUsed)
      if (firstUnused === null) {
        break
      }
      allUnusedStarts.push(firstUnused)
      firstUsed = firstUnused
    }

    const filtered = allUnusedStarts.filter((num) => {

      if (!set.has(num - 1)) { return false }
      if (!set.has(num - 2)) { return false }

      if (set.has(num + 1)) { return false }
      if (set.has(num + 2)) { return false }

      return true
    })

    const res = filtered.length === 0 ? allUnusedStarts : filtered

    return res.map((num) => num.toString())
  }

}

</script>
