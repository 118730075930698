<template>
    <v-app light>
      <v-main>

        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="6" md="6" lg="5" xl="4">

              <v-card raised class="mt-4" elevation="5">
                <v-card-title class="headline">
                  Login
                </v-card-title>
                <v-card-text>
                  <v-text-field label="User Name" v-model="loginUser" autofocus></v-text-field>
                  <v-text-field label="Password" v-model="loginPass" type="password" v-on:keyup.enter="login"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="mx-2 mb-2 px-4" @click="login">LogIn</v-btn>
                </v-card-actions>
              </v-card>
              <v-alert color="error" icon="warning" dismissible v-model="hasAlert">
                {{alertText}}
              </v-alert>

            </v-col>
          </v-row>
        </v-container>

      </v-main>
    </v-app>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator'
import { getConnectionBackend } from './connection'

@Component({
  components: {
  },
})
export default class Login extends Vue {

  loginUser = ''
  loginPass = '' // 'Thbed7632c87z43_fnjf84dkjdjhd'
  alertText = ''
  hasAlert = false

  login(): void {

    this.resetAlarm()

    const instance = getConnectionBackend()

    instance
      .get('Login', {
        params: {
          user: this.loginUser,
          pass: this.loginPass,
        }
      })
      .then((response) => {
        const data = response.data
        console.info(data)
        // const vendor = data.isVendor
        const token: string = data.token
        const vendorNames: string[] = data.names
        const eventPayload = {
          token,
          vendorNames
        }
        this.$emit('loginSuccess', eventPayload)
        this.loginPass = ''
      })
      .catch( (error) => {
        console.info(error.response.data)
        this.loginPass = ''
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.setAlarm(error.response.data.message)
        } else {
          this.setAlarm('Connect error.')
        }
      })
  }

  resetAlarm(): void {
    this.hasAlert = false
    this.alertText = ''
  }

  setAlarm(text: string): void {
    this.alertText = text
    this.hasAlert = true
  }
}

</script>

