<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      no-data-text="No events"
      dense
      hide-default-footer
      :footer-props="footer"
      @click:row="editItem"
      :item-class="() => 'clickable'"
      class="elevation-1">

      <template v-slot:[`item.icon`]>
        <v-icon>event</v-icon>
      </template>

    </v-data-table>
    <dlg-event ref="dlgEditEvent" :db="db"></dlg-event>
  </div>
</template>

<script lang="ts">

import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import * as data from '../types'
import { DbVendor } from '../DbVendor'
import DlgEvent from '../dialogs/DlgEvent.vue'

@Component({
  components: {
    DlgEvent,
  },
})
export default class RelatedEvents extends Vue {

  @Prop(Object) db!: DbVendor
  @Prop({ default() { return null } }) customer!: data.CustomerRow | null
  @Prop({ default() { return null } }) contact!: data.ContactRow | null

  footer = {
    showFirstLastPage: false,
    itemsPerPageOptions: [5000],
  }
  headers = [
    { text: '',         align: 'left',  sortable: false, value: 'icon'           },
    { text: 'Name',     align: 'right', sortable: true,  value: 'event_name'     },
    { text: 'Date',     align: 'left',  sortable: true,  value: 'event_date'     },
    { text: 'Location', align: 'left',  sortable: true,  value: 'event_location' },
  ]

  items: data.EventRow[] = []

  mounted(): void {
    this.onLoad()
  }

  @Watch('customer')
  watch_Customer(): void {
    this.onLoad()
  }

  @Watch('contact')
  watch_Contact(): void {
    this.onLoad()
  }

  async onLoad(): Promise<void> {
    if (this.customer !== null) {
      this.items = await this.db.events_for_customer(this.customer.id)
    }
    else if (this.contact !== null) {
      this.items = await this.db.events_for_contact(this.contact.id)
    }
  }

  async editItem(event: data.EventRow): Promise<void> {
    const dlgEditEvent = this.$refs.dlgEditEvent as DlgEvent
    if (await dlgEditEvent.edit(event.id)) {
      this.onLoad()
    }
  }
}

</script>

