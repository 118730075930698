<template>

  <dlg-obj typeName="Ticket Item"
           :defaultWidth="850"
           :getCurrentObject="getCurrentObject"
           :saveObject="saveObject"
           :isValid="isValid"
           v-slot="{obj}"
           @focus="onFocus"
           ref="theDialog">

    <v-container>

      <v-row>
        <v-col>
          <edit-date  label="Date"  v-model="obj.date" width="9em"></edit-date>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea label="Notes" v-model="obj.notes" hide-details ref="txtNotes" rows="20"></v-textarea>
        </v-col>
      </v-row>

    </v-container>

  </dlg-obj>

</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator'
import { DbVendor } from '../DbVendor'
import DlgObj from './DlgObj.vue'
import EditDate from '../components/EditDate.vue'
import * as data from './../types'

@Component({
  components: {
    DlgObj,
    EditDate,
  },
})
export default class DlgActivityItem extends Vue {

  @Prop(Object) db!: DbVendor

  isValid(obj: data.ActivityItem): boolean {
    return obj.notes !== '' && obj.date !== '' && obj.date !== undefined
  }

  getCurrentObject(id: number): Promise<data.ActivityItem> {
    return this.db.activity_item(id)
  }

  saveObject(obj: data.ActivityItem, is_new: boolean): Promise<number | null> {
    return this.db.activity_item_write(obj, is_new)
  }

  onFocus(): void {
    const element = this.$refs.txtNotes as HTMLElement
    if (element) {
      element.focus()
    }
  }

  add_new(activity_id: number): Promise<number | null> {
    const dlg = this.$refs.theDialog as DlgObj
    const newObj = data.getDefaultActivityItem()
    newObj.activity_id = activity_id
    return dlg.add_new(newObj)
  }

  edit(id: number): Promise<boolean> {
    const dlg = this.$refs.theDialog as DlgObj
    return dlg.edit(id, data.getDefaultActivityItem())
  }
}

</script>
