import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.the_max_width,"persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c(VBtn,{attrs:{"color":"grey darken-1","text":""},nativeOn:{"click":function($event){return _vm.switchWidth.apply(null, arguments)}}},[_vm._v(_vm._s('[-]'))])],1),_c(VCardText,[_vm._t("default",null,{"obj":_vm.obj})],2),_c(VCardActions,{staticClass:"pt-0"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey darken-1","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary darken-1","disabled":!_vm.canOK,"text":""},nativeOn:{"click":function($event){return _vm.ok.apply(null, arguments)}}},[_vm._v("OK")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }