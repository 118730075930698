<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      no-data-text="No tickets"
      dense
      hide-default-footer
      :footer-props="footer"
      @click:row="editItem"
      :item-class="() => 'clickable'"
      class="elevation-1">

      <template v-slot:[`header.icon`]>
        <v-icon @click="addItem">mdi-plus</v-icon>
      </template>

      <template v-slot:[`item.icon`]>
        <v-icon>history</v-icon>
      </template>

    </v-data-table>
    <dlg-activity ref="dlgEditActivity" :db="db"></dlg-activity>
  </div>
</template>

<script lang="ts">

import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import * as data from '../types'
import { DbVendor } from '../DbVendor'
import DlgActivity from '@/dialogs/DlgActivity.vue'

@Component({
  components: {
    DlgActivity,
  },
})
export default class RelatedActivities extends Vue {

  @Prop(Object) db!: DbVendor
  @Prop({ default() { return null } }) customer!: data.CustomerRow | null
  @Prop({ default() { return null } }) contact!: data.ContactRow | null
  @Prop({ default() { return false } }) hideCustomer!: boolean
  @Prop({ default() { return false } }) hideContact!: boolean

  footer = {
    showFirstLastPage: false,
    itemsPerPageOptions: [5000],
  }

  get headers(): Record<string, unknown>[] {
    const customer = 'Customer'
    const contact = 'Contact'
    let columns = [
      { text: '',         align: 'left', sortable: false, value: 'icon'        },
      { text: 'Last',     align: 'left', sortable: true,  value: 'created_at'  }, // TODO genDate!!!
      { text: 'Subject',  align: 'left', sortable: true,  value: 'subject'     },
      { text: 'Status',   align: 'left', sortable: true,  value: 'status'      },
      { text: 'Prio',     align: 'left', sortable: true,  value: 'priority'    },
      { text: 'User',     align: 'left', sortable: true,  value: 'assigned_to' },
      { text: customer,   align: 'left',  sortable: true,  value: 'customer'   },
      { text: contact,    align: 'left',  sortable: true,  value: 'contact'    },
      { text: 'Due',      align: 'left', sortable: true,  value: 'due_at'      },
    ]
    if (this.hideCustomer) {
      columns = columns.filter((col) => col.text !== customer)
    }
    if (this.hideContact) {
      columns = columns.filter((col) => col.text !== contact)
    }
    return columns
  }

  items: data.ActivityRow[] = []

  mounted(): void {
    this.onLoad()
  }

  @Watch('customer')
  watch_Customer(): void {
    this.onLoad()
  }

  @Watch('contact')
  watch_Contact(): void {
    this.onLoad()
  }

  async onLoad(): Promise<void> {
    if (this.customer !== null) {
      this.items = await this.db.customer_activities(this.customer.id)
    }
    else if (this.contact !== null) {
      this.items = await this.db.contact_activities(this.contact.id)
    }
  }

  async addItem(): Promise<void> {
    const dlgEditActivity = this.$refs.dlgEditActivity as DlgActivity
    if (this.customer !== null) {
      if (await dlgEditActivity.add_new(this.customer)) {
        this.onLoad()
      }
    }
    else if (this.contact !== null) {
      if (await dlgEditActivity.add_new(undefined, this.contact)) {
        this.onLoad()
      }
    }
  }

  async editItem(activity: data.ContactRow): Promise<void> {
    const dlgEditActivity = this.$refs.dlgEditActivity as DlgActivity
    if (await dlgEditActivity.edit(activity.id)) {
      this.onLoad()
    }
  }

}

</script>

