<template>
  <div @keydown.esc="onEscape">
    <v-data-table
      :no-data-text="noDataText"
      :headers="headers"
      :items="filteredItems"
      item-key="id"
      :footer-props="footer"
      show-expand
      :expanded.sync="expanded"
      class="elevation-4 mt-2">

      <template v-slot:top>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field v-model="search" label="Search" clearable class="mx-4"></v-text-field>
            </v-col>
            <v-col cols="auto" class="mt-5 mr-3">
              <v-btn small text @click="dump()"><v-icon>cloud_download</v-icon></v-btn>
            </v-col>
            <v-col cols="auto" class="mt-5 mr-3">
              <v-btn small color="primary" @click="addItem()"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.event_name`]="{ item }">
        <span class="clickable" @click="editItem(item)">{{ item.event_name }}</span>
      </template>

      <template v-slot:[`item.event_participants`]="{ item }">
        <div v-if="item.event_participants > 0" class="clickable" @click="dumpParticipants(item)">
          <v-icon  class="mr-4">cloud_download</v-icon><span>{{ item.event_participants }}</span>
        </div>
        <div v-else>0</div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">

          <v-container>

            <v-row>
              <v-col cols="auto">
                <related-event-participants v-if="isExpanded(item)" :db="db" :event="item"></related-event-participants>
              </v-col>
            </v-row>

          </v-container>

        </td>
      </template>

    </v-data-table>
    <dlg-event ref="dlgEditEvent" :db="db"></dlg-event>
  </div>
</template>

<script lang="ts">

import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import * as data from './../types'
import RelatedEventParticipants from './RelatedEventParticipants.vue'
import { DbVendor } from '../DbVendor'
import DlgEvent from '../dialogs/DlgEvent.vue'

@Component({
  components: {
    RelatedEventParticipants,
    DlgEvent,
  },
})
export default class TableEvent extends Vue {

  @Prop(Object) db!: DbVendor

  search = ''
  noDataText = 'No events'
  footer = {
    showFirstLastPage: true,
    itemsPerPageOptions: [15, 50, 100, { text: 'All', value: -1 }],
  }
  headers = [
    { text: 'Name',         align: 'right', sortable: true, value: 'event_name' },
    { text: 'Date',         align: 'left',  sortable: true, value: 'event_date' },
    { text: 'Participants', align: 'right', sortable: true, value: 'event_participants' },
    { text: 'Location',     align: 'left',  sortable: true, value: 'event_location' },
    { text: '',                                             value: 'data-table-expand' },
  ]
  expanded: data.EventRow[] = []

  urlSelect = false

  mounted(): void {
    const params = (new URL(window.location.toString())).searchParams;
    const tab = params.get('tab')
    const search = params.get('search')
    if (tab === 'events' && search !== null) {
      this.search = search
      this.urlSelect = true
    }
  }

  @Watch('filteredItems')
  watch_filteredItems(): void {
    const items = this.filteredItems
    if (items.length === 1 && this.urlSelect) {
      this.expanded = [items[0]]
      this.urlSelect = false
    }
  }
  
  get filteredItems(): data.EventRow[] {

    const search = (this.search || '').toLowerCase()
    const words = search.split(' ').filter((w) => w !== '')
    const posWords = words.filter((w) => !w.startsWith('-'))
    const negWords = words.filter((w) => w.startsWith('-')).map((w) => w.substring(1)).filter((s) => s.length > 0)

    return this.db.events.filter((item) => {
      const valLower = (item.event_name + ' ' +
                        item.event_location).toLowerCase()
      return posWords.every((word) => valLower.indexOf(word) !== -1) &&
             negWords.every((word) => valLower.indexOf(word) === -1)
    })
  }

  isExpanded(evt: data.EventRow): boolean {
    return this.expanded.findIndex((e) => e.id === evt.id) > -1
  }

  onEscape(): void {
    this.expanded.splice(0)
  }

  async addItem(): Promise<void> {
    const dlgEditEvent = this.$refs.dlgEditEvent as DlgEvent
    await dlgEditEvent.add_new()
  }

  async editItem(event: data.EventRow): Promise<void> {
    const dlgEditEvent = this.$refs.dlgEditEvent as DlgEvent
    await dlgEditEvent.edit(event.id)
  }
   
  async dumpParticipants(event: data.EventRow): Promise<void> {
    const participants = await this.db.event_participants(event.id)
    for (let parti of participants) { // eslint-disable-next-line
      const partiAny: any = parti
      partiAny.id = undefined
      partiAny.mailing = data.mapMailing2Bool(parti.mailing)
    }
    await this.db.dumpTable('Event Participants', participants)
    console.info(participants)
  }

  async dump(): Promise<void> {
    await this.db.dumpEvents()
  }
}

</script>

