<template>

  <dlg-obj typeName="License History"
           :defaultWidth="750"
           :getCurrentObject="getCurrentObject"
           :saveObject="saveObject"
           :isValid="isValid"
           v-slot="{obj}"
           @focus="onFocus"
           ref="theDialog">

    <v-container>

      <v-row dense>
        <v-col>
          <edit-date label="When" v-model="obj.change_date" width="8em"></edit-date>
        </v-col>
      </v-row>

      <v-row>
        <v-col>

          <v-btn-toggle v-model="obj.change_actor_id" dense>
            <v-btn v-for="login in db.all_vendor_logins().filter(it => it.can_login)" :key="login.id" :value="login.id">
              {{ login.id === obj.change_actor_id ? '\u2713 ' + login.login : login.login }}
            </v-btn>
            <v-btn v-for="login in db.all_vendor_logins().filter(it => !it.can_login)" :key="login.id" :value="login.id" color="white" style="text-decoration: line-through;">
              {{ login.id === obj.change_actor_id ? '\u2713 ' + login.login : login.login }}
            </v-btn>
          </v-btn-toggle>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea label="Change Comment" v-model="obj.change_comment" ref="txtFocusStart" hide-details></v-textarea>
        </v-col>
      </v-row>

    </v-container>

  </dlg-obj>

</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator'
import { DbVendor } from '../DbVendor'
import DlgObj from './DlgObj.vue'
import EditDate from '../components/EditDate.vue'
import * as data from './../types'

@Component({
  components: {
    DlgObj,
    EditDate,
  },
})
export default class DlgLicenseHistory extends Vue {

  @Prop(Object) db!: DbVendor

  isValid(obj: data.LicenseHistoryMeta): boolean {
    return obj.change_date !== ''
  }

  getCurrentObject(id: number): Promise<data.LicenseHistoryMeta> {
    return this.db.license_history_meta(id)
  }

  saveObject(obj: data.LicenseHistoryMeta): Promise<number | null> {
    return this.db.license_history_meta_write(obj)
  }

  onFocus(): void {
    const element = this.$refs.txtFocusStart as HTMLElement
    if (element) {
      element.focus()
    }
  }

  edit(id: number): Promise<boolean> {
    const dlg = this.$refs.theDialog as DlgObj
    return dlg.edit(id, {})
  }
}

</script>
