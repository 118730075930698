<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      no-data-text="No contacts"
      dense
      hide-default-footer
      :footer-props="footer"
      @click:row="editItem"
      :item-class="() => 'clickable'"
      class="elevation-1">

      <template v-slot:[`header.icon`]>
        <v-icon @click="addItem">mdi-plus</v-icon>
      </template>

      <template v-slot:[`item.icon`]>
        <v-icon>person</v-icon>
      </template>

      <template v-slot:[`item.last_name`]="{ item }">
        <span :class="{ retired: item.retired }">{{ item.last_name }}</span>
      </template>

      <template v-slot:[`item.first_name`]="{ item }">
        <span :class="{ retired: item.retired }">{{ item.first_name }}</span>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <span :class="{ retired: item.retired }">{{ item.email }}</span>
      </template>

      <template v-slot:[`item.street`]="{ item }">
        <span :class="{ retired: item.retired }">{{ formatAddress(item) }}</span>
      </template>

    </v-data-table>
    <dlg-contact ref="dlgEditContact" :db="db"></dlg-contact>
  </div>
</template>

<script lang="ts">

import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import * as data from '../types'
import { DbVendor } from '../DbVendor'
import DlgContact from '../dialogs/DlgContact.vue'

@Component({
  components: {
    DlgContact,
  },
})
export default class RelatedContacts extends Vue {

  @Prop(Object) customer!: data.CustomerRow
  @Prop(Object) db!: DbVendor

  footer = {
    showFirstLastPage: false,
    itemsPerPageOptions: [5000],
  }
  headers = [
    { text: '',           align: 'left', sortable: false, value: 'icon'       },
    { text: 'Last Name',  align: 'left', sortable: true,  value: 'last_name'  },
    { text: 'First Name', align: 'left', sortable: true,  value: 'first_name' },
    { text: 'Customer',   align: 'left', sortable: true,  value: 'customer'   },
    { text: 'E-Mail',     align: 'left', sortable: true,  value: 'email'      },
    { text: 'Phone',      align: 'left', sortable: true,  value: 'phone'      },
    { text: 'Address',    align: 'left', sortable: false, value: 'street'     },
  ]

  items: data.ContactRow[] = []

  mounted(): void {
    this.onLoad()
  }

  @Watch('customer')
  watch_Customer(): void {
    this.onLoad()
  }

  async onLoad(): Promise<void> {
    this.items = await this.db.customer_contacts(this.customer.id)
  }

  async addItem(): Promise<void> {
    const dlgEditContact = this.$refs.dlgEditContact as DlgContact
    if (await dlgEditContact.add_new(this.customer.id)) {
      this.onLoad()
    }
  }

  async editItem(contact: data.ContactRow): Promise<void> {
    const dlgEditContact = this.$refs.dlgEditContact as DlgContact
    if (await dlgEditContact.edit(contact.id)) {
      this.onLoad()
    }
  }

  formatAddress(it: data.ContactRow): string {
    let res = ''
    if (it.street1 != null && it.street1 !== '') {
      res = it.street1
    }
    if (it.street2 != null && it.street2 !== '') {
      if (res.length > 0) { res += ', ' }
      res += it.street2
    }
    if (it.postal !== null && it.postal !== '' && it.town !== null && it.town !== '') {
      if (res.length > 0) { res += ', ' }
      res += (it.postal + ' ' + it.town)
    }
    return res
  }
}

</script>

