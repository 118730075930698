<template>

  <v-dialog v-model="dialog" @keydown.esc="cancel" persistent fullscreen>
    <v-card>

      <v-toolbar dark color="primary">
        <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Change Log</v-toolbar-title>
      </v-toolbar>

      <v-card-text>        
        <v-data-table
            dense
            :no-data-text="noDataText"
            :headers="headers"
            :items="filteredItems"
            :footer-props="footer"
            show-expand
            item-key="date"
            :expanded.sync="expanded"
            class="elevation-4 mt-2">

            <template v-slot:top>
              <v-container>
                <v-row>
                    <v-col>
                        <v-text-field v-model="search" label="Search" clearable class="mx-4"></v-text-field>
                    </v-col>
                    <v-col cols="auto" class="mt-5 mr-3">
                        <v-btn small text @click="dump()"><v-icon>cloud_download</v-icon></v-btn>
                    </v-col>
                </v-row>
              </v-container>
            </template>

            <template v-slot:[`item.date`]="{ item }">
                {{ formatDate(item.date) }}
            </template>

             <template v-slot:[`item.changes`]="{ item }">
                {{ formatChanges(item.changes) }}
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">

                    <v-container>

                        <v-row>
                          <v-col cols="auto">

                            <table>
                                <thead>
                                    <tr>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="it in objProperties(item)" :key="it.name">
                                        <td><span :class="{ bold: it.changed }">{{ it.name }}</span></td>
                                        <td><span :class="{ bold: it.changed }">{{ it.value }}</span></td>
                                    </tr>
                                </tbody>
                            </table>

                          </v-col>
                        </v-row>

                    </v-container>

                </td>
            </template>

        </v-data-table>
      </v-card-text>      
    </v-card>
  </v-dialog>

</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator'
import * as data from './../types'
import { DbVendor } from '../DbVendor'

interface Item {
  name: string
  value: string
  changed: boolean
}

@Component({
  components: {
  },
})
export default class DlgChangeLog extends Vue {

  @Prop(Object) db!: DbVendor

  dialog = false

  search = ''
  noDataText = 'No changes'
  footer = {
    showFirstLastPage: true,
    itemsPerPageOptions: [15, 50, 100, { text: 'All', value: -1 }],
  }
  headers = [
    { text: 'Date',      align: 'right', sortable: true,  value: 'date' },
    { text: 'Who',       align: 'left',  sortable: true,  value: 'who'  },
    { text: 'Change',    align: 'left',  sortable: true,  value: 'change_type' },
    { text: 'Entity',    align: 'left',  sortable: true,  value: 'entity'    },
    { text: 'ID',        align: 'left',  sortable: true,  value: 'entity_id' },
    { text: 'Title',     align: 'left', sortable: false, value: 'info_text' },
    { text: 'Changes',   align: 'left', sortable: false, value: 'changes'   },
    { text: '',                                           value: 'data-table-expand' },
  ]
  expanded: data.ChangeLogEntry[] = []

  items: data.ChangeLogEntry[] = []

  get filteredItems(): data.ChangeLogEntry[] {

    const search = (this.search || '').toLowerCase()
    const words = search.split(' ').filter((w) => w !== '')
    const posWords = words.filter((w) => !w.startsWith('-'))
    const negWords = words.filter((w) => w.startsWith('-')).map((w) => w.substring(1)).filter((s) => s.length > 0)

    return this.items.filter((item) => {
      const valLower = (item.who + ' ' +
                        item.change_type + ' ' +
                        item.entity + ' ' +
                        item.entity_id + ' ' +
                        item.info_text + ' ' +
                        item.changes).toLowerCase()
      return posWords.every((word) => valLower.indexOf(word) !== -1) &&
             negWords.every((word) => valLower.indexOf(word) === -1)
    })
  }

  formatDate(t: string): string {
    return t.substring(0, 19).replace('T', '\u00A0')
  }

  formatChanges(t: string | null): string {
      if (t === null || t === '') { return '' }
      let s = t;
      if (t[0] === '{' && t[t.length - 1] === '}') {
          s = t.substring(1, t.length - 1)
      }
      return s.substring(0, 100)
  }

  objProperties(item: data.ChangeLogEntry): Item[] {
      const changes = item.changes || '{}'
      const s = item.row_values
      if (s === null || s === 'null' || s === '') { return [] }
      const jsonValues = JSON.parse(s)
      const jsonChanges = JSON.parse(changes)
      const changedKeys = new Set(Object.entries(jsonChanges).map(([key, ]) => key))
      const items: Item[] = Object.entries(jsonValues).map(([key, v]) => { 
          const value: string = (typeof v === 'string') ? v : JSON.stringify(v)
          const changed = changedKeys.has(key)
          return { name: key, value, changed}
      })
      return items
  }

  async show(): Promise<void> {
    this.dialog = true
    this.items = await this.db.get_change_log()
  }

  cancel(): void {
    this.dialog = false
  }

  async dump(): Promise<void> {
    await this.db.dumpTable('Change', this.items)
  }

}

</script>
