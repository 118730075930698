<template>

  <dlg-obj typeName="Address"
           :defaultWidth="500"
           :getCurrentObject="getCurrentObject"
           :saveObject="saveObject"
           :isValid="isValid"
           v-slot="{obj}"
           @focus="onFocus"
           ref="theDialog">

    <v-container>

      <v-row>
        <v-col>
          <v-text-field label="Street 1" v-model="obj.street1" ref="txtFocusStart" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field label="Street 2" v-model="obj.street2" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto">
          <v-text-field label="Postal Code" v-model="obj.postal_code" style="max-width:8em" hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Town" v-model="obj.town" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field label="Territory" v-model="obj.territory" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field label="Country" v-model="obj.country" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>

          <v-btn-toggle v-model="obj.addr_type" dense>
            <v-btn v-for="type in addrTypes" :key="type" :value="type">
              {{ type === obj.addr_type ? '\u2713 ' + type : type }}
            </v-btn>
          </v-btn-toggle>

        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-textarea   label="Notes"    v-model="obj.notes" hide-details></v-textarea>
        </v-col>
      </v-row>

    </v-container>

  </dlg-obj>

</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator'
import { DbVendor } from '../DbVendor'
import DlgObj from './DlgObj.vue'
import * as data from '../types'

@Component({
  components: {
    DlgObj,
  },
})
export default class DlgAddress extends Vue {

  @Prop(Object) db!: DbVendor

  get addrTypes(): string[] {
    return this.db.enum_addrtype()
  }

  isValid(obj: data.Address): boolean {
    return obj.street1 !== '' && obj.town !== ''
  }

  getCurrentObject(id: number): Promise<data.Address> {
    return this.db.address(id)
  }

  saveObject(obj: data.Address, is_new: boolean): Promise<number | null> {
    return this.db.address_write(obj, is_new)
  }

  onFocus(): void {
    const element = this.$refs.txtFocusStart as HTMLElement
    if (element) {
      element.focus()
    }
  }

  add_new(customer_id: number): Promise<number | null> {
    const dlg = this.$refs.theDialog as DlgObj
    const newObj = data.getDefaultAddress()
    newObj.customer_id = customer_id
    return dlg.add_new(newObj)
  }

  edit(id: number): Promise<boolean> {
    const dlg = this.$refs.theDialog as DlgObj
    return dlg.edit(id, data.getDefaultAddress())
  }
}

</script>
