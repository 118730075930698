<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      no-data-text="No addresses"
      dense
      hide-default-footer
      :footer-props="footer"
      @click:row="editItem"
      :item-class="() => 'clickable'"
      class="elevation-1">

      <template v-slot:[`header.icon`]>
        <v-icon @click="addItem">mdi-plus</v-icon>
      </template>

      <template v-slot:[`item.icon`]>
        <v-icon>mail</v-icon>
      </template>

    </v-data-table>
    <dlg-address ref="dlgEditAddress" :db="db"></dlg-address>
  </div>
</template>

<script lang="ts">

import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import * as data from '../types'
import { DbVendor } from '../DbVendor'
import DlgAddress from '../dialogs/DlgAddress.vue'

@Component({
  components: {
    DlgAddress,
  },
})
export default class RelatedAddresses extends Vue {

  @Prop(Object) customer!: data.CustomerRow
  @Prop(Object) db!: DbVendor

  footer = {
    showFirstLastPage: false,
    itemsPerPageOptions: [5000],
  }
  headers = [
    { text: '',            align: 'left',  sortable: false,  value: 'icon'        },
    { text: 'Type',        align: 'left',  sortable: true,   value: 'addr_type'   },
    { text: 'Country',     align: 'left',  sortable: true,   value: 'country'     },
    { text: 'Postal Code', align: 'left',  sortable: true,   value: 'postal_code' },
    { text: 'Town',        align: 'left',  sortable: true,   value: 'town'        },
    { text: 'Street1',     align: 'left',  sortable: true,   value: 'street1'      },
    { text: 'Street2',     align: 'left',  sortable: true,   value: 'street2'      },
    { text: 'Used',        align: 'right', sortable: false,  value: 'count'       },
  ]

  items: data.AddressRow[] = []

  mounted(): void {
    this.onLoad()
  }

  @Watch('customer')
  watch_Customer(): void {
    this.onLoad()
  }

  async onLoad(): Promise<void> {
    this.items = await this.db.addresses_for_customer(this.customer.id)
  }

  async addItem(): Promise<void> {
    const dlgEditAddress = this.$refs.dlgEditAddress as DlgAddress
    if (await dlgEditAddress.add_new(this.customer.id)) {
      this.onLoad()
    }
  }

  async editItem(address: data.AddressRow): Promise<void> {
    const dlgEditAddress = this.$refs.dlgEditAddress as DlgAddress
    if (await dlgEditAddress.edit(address.id)) {
      this.onLoad()
    }
  }

}

</script>