<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      no-data-text="No licenses"
      dense
      hide-default-footer
      :footer-props="footer"
      @click:row="editItem"
      :item-class="() => 'clickable'"
      class="elevation-1">

      <template v-slot:[`header.icon`]>
        <v-icon @click="addItem">mdi-plus</v-icon>
      </template>

      <template v-slot:[`item.icon`]>
        <v-icon>security</v-icon>
      </template>

    </v-data-table>
    <dlg-license ref="dlgEditLicense" :db="db"></dlg-license>
  </div>
</template>

<script lang="ts">

import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import * as data from '../types'
import { DbVendor } from '../DbVendor'
import DlgLicense from '../dialogs/DlgLicense.vue'

@Component({
  components: {
    DlgLicense,
  },
})
export default class RelatedLicenses extends Vue {

  @Prop(Object) db!: DbVendor
  @Prop({ default() { return null } }) customer!: data.CustomerRow | null
  @Prop({ default() { return null } }) contact!: data.ContactRow | null
  @Prop({ default() { return false } }) hideCustomer!: boolean
  @Prop({ default() { return false } }) hideContact!: boolean

  footer = {
    showFirstLastPage: false,
    itemsPerPageOptions: [5000],
  }

  get headers(): Record<string, unknown>[] {
    const customer = 'Customer'
    const contact = 'Contact'
    let columns = [
      { text: '',         align: 'left',  sortable: false, value: 'icon'        },
      { text: 'Number',   align: 'left',  sortable: true,  value: 'lic_number'  },
      { text: 'App',      align: 'left',  sortable: true,  value: 'app'         },
      { text: 'Ver',      align: 'left',  sortable: true,  value: 'ver'         },
      { text: customer,   align: 'left',  sortable: true,  value: 'customer'    },
      { text: contact,    align: 'left',  sortable: true,  value: 'contact'     },
      { text: 'Type',     align: 'left',  sortable: true,  value: 'lic_type'    },
      { text: 'Features', align: 'left',  sortable: true,  value: 'features'    },
      { text: 'Academic', align: 'left',  sortable: true,  value: 'academic'    },
      { text: 'Lock',     align: 'left',  sortable: true,  value: 'protection'  },
      { text: 'Created',  align: 'left',  sortable: true,  value: 'created_at'  },
      { text: 'Valid',    align: 'left',  sortable: true,  value: 'valid_until' },
    ]
    if (this.hideCustomer) {
      columns = columns.filter((col) => col.text !== customer)
    }
    if (this.hideContact) {
      columns = columns.filter((col) => col.text !== contact)
    }
    return columns
  }

  items: data.LicenseRow[] = []

  mounted(): void {
    this.onLoad()
  }

  @Watch('customer')
  watch_Customer(): void {
    this.onLoad()
  }

  @Watch('contact')
  watch_Contact(): void {
    this.onLoad()
  }

  async onLoad(): Promise<void> {
    if (this.customer !== null) {
      this.items = await this.db.customer_licenses(this.customer.id)
    }
    else if (this.contact !== null) {
      this.items = await this.db.contact_licenses(this.contact.id)
    }
  }

  async addItem(): Promise<void> {
    const dlgEditLicense = this.$refs.dlgEditLicense as DlgLicense
    if (this.customer !== null) {
      if (await dlgEditLicense.add_new(this.customer)) {
        this.onLoad()
      }
    }
    else if (this.contact !== null) {
      if (await dlgEditLicense.add_new(undefined, this.contact)) {
        this.onLoad()
      }
    }
  }

  async editItem(license: data.LicenseRow): Promise<void> {
    const dlgEditLicense = this.$refs.dlgEditLicense as DlgLicense
    if (await dlgEditLicense.edit(license.id)) {
      this.onLoad()
    }
  }

}

</script>

