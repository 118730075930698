import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"margin":"1em"}},[_c('p',{staticStyle:{"font-size":"1.2em"},domProps:{"innerHTML":_vm._s(_vm.replaceLineBreaks(_vm.notes))}}),_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":_vm.addItem}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Add item ")],1),_c(VContainer,_vm._l((_vm.items),function(item){return _c(VRow,{key:item.id,staticStyle:{"font-size":"1.2em"}},[_c(VCol,{staticClass:"clickable",staticStyle:{"font-weight":"bold"},attrs:{"cols":"auto"}},[_c('span',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(item.date))])]),_c(VCol,{staticClass:"clickable",attrs:{"cols":"auto"}},[_c('span',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(item.actor))])]),_c(VCol,[_c('p',{domProps:{"innerHTML":_vm._s(_vm.replaceLineBreaks(item.notes))}})])],1)}),1),_c('dlg-activity-item',{ref:"dlgEditItem",attrs:{"db":_vm.db}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }