<template>
  <div>

    <template v-if="loginRequired">
      <login @loginSuccess="onLoginSuccess"></login>
    </template>

    <template v-else>
      <app-vendor :token="token" :email="email" :vendors="vendors" @logout="onLogout" ></app-vendor>
    </template>

  </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator'
import Login from './Login.vue'
import AppVendor from './AppVendor.vue'
import * as data from './types'

interface Token {
  email: string,
  vendors: number[]
  exp: number
}

@Component({
  components: {
    Login,
    AppVendor,
  },
})
export default class App extends Vue {

  email = ''
  vendors: data.IdName[] = []
  token = ''

  get loginRequired(): boolean {
    return this.token === ''
  }

  created(): void {
    const dark = window.localStorage.getItem('is_dark_mode')
    this.$vuetify.theme.dark = (dark === 'true')

    const token = window.localStorage.getItem('token')
    const vendor_names = window.localStorage.getItem('vendor_names')
    if (token && vendor_names) {
      const t = this.parseJwt(token)
      const expires = new Date(t.exp * 1000)
      const now = new Date()
      if (expires < now) {
        console.info('Login token is expired at ' + expires)
      }
      else {
        console.info('Login token will expire at ' + expires)
        const vendorNames: string[] = JSON.parse(vendor_names)
        this.init(token, vendorNames)
      }
    }
  }

  onLoginSuccess(e: { token: string, vendorNames: string[]}): void {

    console.info('Login success: ' + e.token)
    console.info('VendorNames: ' + JSON.stringify(e.vendorNames))

    window.localStorage.setItem('token', e.token)
    window.localStorage.setItem('vendor_names', JSON.stringify(e.vendorNames))

    this.init(e.token, e.vendorNames)
  }

  init(theToken: string, vendorNames: string[]): void {

    const token = this.parseJwt(theToken)
    this.email = token.email

    const ids: number[] = token.vendors
    const names: string[] = vendorNames

    this.vendors = ids.map((id, index) => { return { id, name: names[index] } } )
    this.token = theToken
  }

  parseJwt(token: string): Token {
    try {
      return JSON.parse(atob(token.split('.')[1]))
    }
    catch (e) {
      return {
        email: '',
        vendors: [],
        exp: 0,
      }
    }
  }

  onLogout(): void {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('vendor_names')
    this.token = ''
  }

}

</script>

<style>

  @media only screen and (min-width: 1100px) {
    .container {
      max-width: 1080px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .container {
      max-width: 1180px;
    }
  }

  @media only screen and (min-width: 1300px) {
    .container {
      max-width: 1280px;
    }
  }

  @media only screen and (min-width: 1400px) {
    .container {
      max-width: 1380px;
    }
  }

  @media only screen and (min-width: 1700px) {
    .container {
      max-width: 1680px;
    }
  }

  @media only screen and (min-width: 1800px) {
    .container {
      max-width: 1780px;
    }
  }

  .clickable:hover {
     cursor: pointer;
     text-decoration: underline;
  }

  .retired {
     text-decoration: line-through;
  }

  .bold {
    font-weight: bold;
  }

</style>