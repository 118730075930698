<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      no-data-text="No participants"
      dense
      hide-default-footer
      :footer-props="footer"
      @click:row="editItem"
      :item-class="() => 'clickable'"
      class="elevation-1">

      <template v-slot:[`header.icon`]>
        <v-icon @click="addItem">mdi-plus</v-icon>
      </template>

      <template v-slot:[`item.icon`]>
        <v-icon>person</v-icon>
      </template>

      <template v-slot:[`item.last_name`]="{ item }">
        <span :class="{ retired: item.retired }">{{ item.last_name }}</span>
      </template>

      <template v-slot:[`item.first_name`]="{ item }">
        <span :class="{ retired: item.retired }">{{ item.first_name }}</span>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <span :class="{ retired: item.retired }">{{ item.email }}</span>
      </template>

    </v-data-table>
    <dlg-event-participant ref="dlgEditParticipant" :db="db"></dlg-event-participant>
  </div>
</template>

<script lang="ts">

import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import * as data from '../types'
import { DbVendor } from '../DbVendor'
import DlgEventParticipant from '../dialogs/DlgEventParticipant.vue'

@Component({
  components: {
    DlgEventParticipant,
  },
})
export default class RelatedEventParticipants extends Vue {

  @Prop(Object) db!: DbVendor
  @Prop(Object) event!: data.EventRow

  footer = {
    showFirstLastPage: false,
    itemsPerPageOptions: [5000],
  }
  headers = [
    { text: '',           align: 'left', sortable: false, value: 'icon'        },
    { text: 'Last Name',  align: 'left', sortable: true,  value: 'last_name'  },
    { text: 'First Name', align: 'left', sortable: true,  value: 'first_name' },
    { text: 'E-Mail',     align: 'left', sortable: true,  value: 'email'      },
    { text: 'Customer',   align: 'left', sortable: true,  value: 'customer'   },
    { text: 'Notes',      align: 'left', sortable: true,  value: 'notes'   },
  ]

  items: data.EventParticipantRow[] = []

  mounted(): void {
    this.onLoad()
  }

  @Watch('event')
  watch_Event(): void {
    this.onLoad()
  }

  async onLoad(): Promise<void> {
    this.items = await this.db.event_participants(this.event.id)
  }

  async addItem(): Promise<void> {
    const dlgEditParticipant = this.$refs.dlgEditParticipant as DlgEventParticipant
    if (await dlgEditParticipant.add_new(this.event)) {
      this.onLoad()
    }
  }

  async editItem(participant: data.EventParticipantRow): Promise<void> {
    const dlgEditParticipant = this.$refs.dlgEditParticipant as DlgEventParticipant
    if (await dlgEditParticipant.edit(participant.id)) {
      this.onLoad()
    }
  }

}

</script>

