<template>

  <dlg-obj typeName="Customer"
           :defaultWidth="550"
           :getCurrentObject="getCurrentObject"
           :saveObject="saveObject"
           :isValid="isValid"
           v-slot="{obj}"
           @focus="onFocus"
           ref="theDialog">

    <v-container>

      <v-row dense>
        <v-col>
          <v-text-field label="Short name" v-model="obj.short_name" ref="txtFocusStart" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-text-field label="Full name"  v-model="obj.full_name" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-text-field label="Full name ext 1"  v-model="obj.full_name_ext1" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-text-field label="Full name ext 2"  v-model="obj.full_name_ext2" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-text-field label="Full name ext 3"  v-model="obj.full_name_ext3" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-text-field label="Domain"     v-model="obj.domain_name" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>

          <v-btn-toggle v-model="obj.type_customer" dense>
            <v-btn v-for="type in db.enum_customertype()" :key="type" :value="type">
              {{ type === obj.type_customer ? '\u2713 ' + type : type }}
            </v-btn>
          </v-btn-toggle>

        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto">
          <v-select     label="Language" v-model="obj.lang" :items="db.enum_langcode()" style="max-width:6em" hide-details></v-select>
        </v-col>
        <v-col>
          <v-text-field label="WWW"      v-model="obj.www" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea   label="Notes"    v-model="obj.notes" hide-details></v-textarea>
        </v-col>
      </v-row>

    </v-container>

  </dlg-obj>

</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator'
import { DbVendor } from '../DbVendor'
import DlgObj from './DlgObj.vue'
import * as data from './../types'

@Component({
  components: {
    DlgObj,
  },
})
export default class DlgCustomer extends Vue {

  @Prop(Object) db!: DbVendor

  isValid(obj: data.Customer): boolean {
    return obj.short_name !== '' && obj.lang !== '' && obj.full_name !== ''
  }

  getCurrentObject(id: number): Promise<data.Customer> {
    return this.db.customer(id)
  }

  saveObject(obj: data.Customer, is_new: boolean): Promise<number | null> {
    return this.db.customer_write(obj, is_new)
  }

  onFocus(): void {
    const element = this.$refs.txtFocusStart as HTMLElement
    if (element) {
      element.focus()
    }
  }

  add_new(): Promise<number | null> {
    const dlg = this.$refs.theDialog as DlgObj
    const newObj = data.getDefaultCustomer()
    return dlg.add_new(newObj)
  }

  edit(id: number): Promise<boolean> {
    const dlg = this.$refs.theDialog as DlgObj
    return dlg.edit(id, data.getDefaultCustomer())
  }
}

</script>
