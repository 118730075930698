<template>

  <v-dialog v-model="dialog" :max-width="the_max_width" @keydown.esc="cancel" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{title}}</span>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click.native="switchWidth">{{ '[-]' }}</v-btn>
      </v-card-title>
      <v-card-text>
        <slot v-bind:obj="obj"></slot>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click.native="cancel">Cancel</v-btn>
        <v-btn color="primary darken-1" :disabled="!canOK" text @click.native="ok">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// eslint-disable-next-line
interface TheClass { }

@Component({
  components: {
  },
})
export default class DlgObj extends Vue {

  @Prop(String) typeName!: string
  @Prop(Number) defaultWidth!: number
  @Prop(Function) getCurrentObject!: (id: number) => Promise<TheClass>
  @Prop(Function) saveObject!: (obj: TheClass, is_new: boolean, obj_original: TheClass) => Promise<number | null>
  @Prop(Function) isValid!: (obj: TheClass) => boolean

  initalized = false

  max_width: number | undefined = 600
  dialog = false
  obj: TheClass = {}
  obj_original_str = '{}'
  is_new = false

  get the_max_width() : number | undefined {
    return this.max_width
  }

  @Watch('dialog')
  watch_dialog(): void {
    if (!this.initalized) {
      this.max_width = this.defaultWidth
      this.initalized = true
    }
  }

  // eslint-disable-next-line
  resolve: ((v: number | null) => void) = (v) => {}

  get title(): string {
    const name = this.typeName
    return this.is_new ? 'New ' + name : 'Edit ' + name
  }

  switchWidth(): void {
    if (this.max_width === this.defaultWidth) {
      this.max_width = undefined
    }
    else {
      this.max_width = this.defaultWidth
    }
  }

  add_new(newObj: TheClass): Promise<number | null> {
    this.dialog = true
    this.is_new = true
    this.obj = newObj
    this.obj_original_str = '{}'
    this.$emit('object', newObj)
    this.focus()
    return new Promise<number | null>((resolve) => {
      this.resolve = resolve
    })
  }

  edit(id: number, emptyObj: TheClass): Promise<boolean> {
    this.dialog = true
    this.is_new = false
    this.obj = emptyObj
    this.obj_original_str = '{}'
    this.focus()
    this.getCurrentObject(id).then((obj) => {
      this.obj = obj
      this.obj_original_str = JSON.stringify(obj)
      this.$emit('object', obj)
      console.info(obj)
    })
    return new Promise<number | null>((resolve) => {
      this.resolve = resolve
    }).then((x) => x !== null)
  }

  focus(): void {
    setTimeout(() => {
      this.$emit('focus')
    }, 100)
  }

  get canOK(): boolean {
    return this.isValid(this.obj) && JSON.stringify(this.obj) !== this.obj_original_str
  }

  ok(): void {
    setTimeout(() => { // workaround for v-combobox delayed update bug
      const objOrig = JSON.parse(this.obj_original_str)
      this.saveObject(this.obj, this.is_new, objOrig).then((idOrNull) => {
        if (idOrNull !== null) {
          this.resolve(idOrNull)
          this.dialog = false
        }
      })
    })
  }

  cancel(): void {
    this.resolve(null)
    this.dialog = false
  }
}

</script>
