
import { AxiosInstance } from 'axios'
import axios from 'axios'
import * as data from './types'
import { getConnectionDB, getConnectionBackend } from './connection'

export class ApiVendor {

  private connection: AxiosInstance
  private vendor = -1
  private token = ''   // eslint-disable-next-line
  private onTokenInvalid = () => {}

  private vendor_logins: data.VendorLogin[] = []
  private apps: data.App[] = []
  private app_versions: data.AppVersion[] = []
  private all_enum_values: data.EnumValue[] = []

  get the_vendor(): number {
    return this.vendor
  }

  constructor(vendor: number, token?: string, onTokenInvalid?: () => void) {

    this.vendor = vendor
    this.connection = axios
    this.token = token || '' // eslint-disable-next-line
    this.onTokenInvalid = onTokenInvalid || (() => {})

    if (token !== undefined && token !== '' && token !== null) {

      this.connection = getConnectionDB(token)

      this.connection.post('/vendor_logins').then((response) => {
        this.vendor_logins = response.data
      })
      this.connection.post('/apps').then((response) => {
        this.apps = response.data
      })
      this.connection.post('/app_versions').then((response) => {
        this.app_versions = response.data
      })
      this.connection.post('/all_enum_values').then((response) => {
        this.all_enum_values = response.data
      })
    }
  }

  all_vendor_logins(): data.VendorLogin[] {
    const thisVendor = this.vendor
    return this.vendor_logins.filter(it => it.vendor_id === thisVendor)
  }

  all_logins(): data.VendorLogin[] {
    return this.vendor_logins
  }

  all_apps(): data.App[] {
    return this.apps
  }

  all_app_versions(): data.AppVersion[] {
    return this.app_versions
  }

  versions_for_app(app_id: number): data.AppVersion[] {
    return this.app_versions.filter((x) => x.app_id === app_id)
  }

  all_enums(): data.EnumValue[] {
    return this.all_enum_values
  }

  activities(): Promise<data.ActivityRow[]> {
    return this.connection.post('/vendor_activities', {
      _vendor_id: this.vendor,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  activity_notes(activity_id: number): Promise<string> {
    return this.connection.post('/vendor_activity_notes', {
      _vendor_id: this.vendor,
      _activity_id: activity_id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  activity_items(activity_id: number): Promise<data.ActivityItemRow[]> {
    return this.connection.post('/vendor_activity_items', {
      _vendor_id: this.vendor,
      _activity_id: activity_id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  activity_item(activity_item_id: number): Promise<data.ActivityItem> {
    return this.connection.post('/vendor_activity_item', {
      _vendor_id: this.vendor,
      _activity_item_id: activity_item_id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  activity_item_write(data: data.ActivityItem, is_new: boolean): Promise<number | null> {
    return this.connection.post('/vendor_activity_item_write', {
      _vendor_id: this.vendor,
      is_new,
      it: data,
    }) // eslint-disable-next-line
    .then((response) => {
      //console.info(response.data)
      return response.data.the_id
    })
    .catch((err) => this.onHandleError(err))
    .then(this.mapFalseToNull)
  }

  contacts(): Promise<data.ContactRow[]> {
    return this.connection.post('/vendor_contacts', {
      _vendor_id: this.vendor,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  contactsChristmas(): Promise<data.ChristmasListEntry[]> {
    return this.connection.post('/vendor_contacts_christmas', {
      _vendor_id: this.vendor,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  contact(id: number): Promise<data.Contact> {
    return this.connection.post('/vendor_contact', {
      _vendor_id: this.vendor,
      _contact_id: id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  contact_write(data: data.Contact, is_new: boolean): Promise<number | null> {
    return this.connection.post('/vendor_contact_write', {
      _vendor_id: this.vendor,
      is_new,
      c: data,
    }) // eslint-disable-next-line
    .then((response) => {
      //console.info(response.data)
      return response.data.the_id
    })
    .catch((err) => this.onHandleError(err))
    .then(this.mapFalseToNull)
  }

  licenses(): Promise<data.LicenseRow[]> {
    return this.connection.post('/vendor_licenses', {
      _vendor_id: this.vendor,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  license(id: number): Promise<data.License> {
    return this.connection.post('/vendor_license', {
      _vendor_id: this.vendor,
      _license_id: id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  license_write(data: data.License, is_new: boolean, change_comment: string, addToHistory: boolean): Promise<number | null> {
    return this.connection.post('/vendor_license_write', {
      _vendor_id: this.vendor,
      is_new,
      l: data,
      _change_comment: change_comment,
      _history: addToHistory,
    }) // eslint-disable-next-line
    .then((response) => {
      //console.info(response.data)
      return response.data.the_id
    })
    .catch((err) => this.onHandleError(err))
    .then(this.mapFalseToNull)
  }

  license_history(license_id: number): Promise<data.LicenseHistory[]> {
    return this.connection.post('/vendor_license_history', {
      _vendor_id: this.vendor,
      _license_id: license_id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  license_history_meta(license_history_id: number): Promise<data.LicenseHistoryMeta> {
    return this.connection.post('/vendor_license_history_meta', {
      _vendor_id: this.vendor,
      _license_history_id: license_history_id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  license_history_meta_write(data: data.LicenseHistoryMeta): Promise<number | null> {
    return this.connection.post('/vendor_license_history_meta_write', {
      _vendor_id: this.vendor,
      meta: data,
    }) // eslint-disable-next-line
    .then((response) => {
      //console.info(response.data)
      return response.data.the_id
    })
    .catch((err) => this.onHandleError(err))
    .then(this.mapFalseToNull)
  }

  events(): Promise<data.EventRow[]> {
    return this.connection.post('/vendor_events', {
      _vendor_id: this.vendor,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  events_for_contact(contact_id: number): Promise<data.EventRow[]> {
    return this.connection.post('/vendor_events_for_contact', {
      _vendor_id: this.vendor,
      _contact_id: contact_id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  events_for_customer(customer_id: number): Promise<data.EventRow[]> {
    return this.connection.post('/vendor_events_for_customer', {
      _vendor_id: this.vendor,
      _customer_id: customer_id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  addresses_for_customer(customer_id: number): Promise<data.AddressRow[]> {
    return this.connection.post('/vendor_addresses_for_customer', {
      _vendor_id: this.vendor,
      _customer_id: customer_id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  address(id: number): Promise<data.Address> {
    return this.connection.post('/vendor_address', {
      _vendor_id: this.vendor,
      _address_id: id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  address_write(data: data.Address, is_new: boolean): Promise<number | null> {
    return this.connection.post('/vendor_address_write', {
      _vendor_id: this.vendor,
      is_new,
      a: data,
    }) // eslint-disable-next-line
    .then((response) => {
      //console.info(response.data.the_id)
      return response.data.the_id
    })
    .catch((err) => this.onHandleError(err))
    .then(this.mapFalseToNull)
  }

  event_participants(event_id: number): Promise<data.EventParticipantRow[]> {
    return this.connection.post('/vendor_event_participants', {
      _vendor_id: this.vendor,
      _event: event_id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  customers(): Promise<data.CustomerRow[]> {
    return this.connection.post('/vendor_customers', {
      _vendor_id: this.vendor,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  customer(id: number): Promise<data.Customer> {
    return this.connection.post('/vendor_customer', {
      _vendor_id: this.vendor,
      _customer_id: id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  customer_write(data: data.Customer, is_new: boolean): Promise<number | null> {
    return this.connection.post('/vendor_customer_write', {
      _vendor_id: this.vendor,
      is_new,
      c: data,
    }) // eslint-disable-next-line
    .then((response) => {
      //console.info(response.data)
      return response.data.the_id
    })
    .catch((err) => this.onHandleError(err))
    .then(this.mapFalseToNull)
  }

  activity(id: number): Promise<data.Activity> {
    return this.connection.post('/vendor_activity', {
      _vendor_id: this.vendor,
      _activity_id: id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  activity_write(data: data.Activity, is_new: boolean): Promise<number | null> {
    return this.connection.post('/vendor_activity_write', {
      _vendor_id: this.vendor,
      is_new,
      it: data,
    }) // eslint-disable-next-line
    .then((response) => {
      //console.info(response.data)
      return response.data.the_id
    })
    .catch((err) => this.onHandleError(err))
    .then(this.mapFalseToNull)
  }

  event(id: number): Promise<data.Event> {
    return this.connection.post('/vendor_event', {
      _vendor_id: this.vendor,
      _event_id: id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  event_write(data: data.Event, is_new: boolean): Promise<number | null> {
    return this.connection.post('/vendor_event_write', {
      _vendor_id: this.vendor,
      is_new,
      it: data,
    }) // eslint-disable-next-line
    .then((response) => {
      //console.info(response.data)
      return response.data.the_id
    })
    .catch((err) => this.onHandleError(err))
    .then(this.mapFalseToNull)
  }

  event_participant(id: number): Promise<data.EventParticipant> {
    return this.connection.post('/vendor_event_participant', {
      _vendor_id: this.vendor,
      _event_p_id: id,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  event_participant_write(data: data.EventParticipant, is_new: boolean): Promise<number | null> {
    return this.connection.post('/vendor_event_participant_write', {
      _vendor_id: this.vendor,
      is_new,
      it: data,
    }) // eslint-disable-next-line
    .then((response) => {
      //console.info(response.data)
      return response.data.the_id
    })
    .catch((err) => this.onHandleError(err))
    .then(this.mapFalseToNull)
  }

  get_change_log(): Promise<data.ChangeLogEntry[]> {
    return this.connection.post('/vendor_change_log', {
      _vendor_id: this.vendor,
    })
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
  }

  createLicenseFile(license_id: number): Promise<data.LicenseFile | null> {

    const instance = getConnectionBackend(this.token)

    return instance.get('/License', {
      params: {
        vendor_id: this.vendor,
        license_id: license_id,
      }
    }) // eslint-disable-next-line
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
    .then(this.mapFalseToNull)
  }

  dumpTable(label: string, entriesJSONStr: string): Promise<Blob | null> {

    const instance = getConnectionBackend(this.token)

    return instance.post('/Xlsx', {
      label,
      entries: entriesJSONStr,
    }, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/octet-stream',
      },
    }) // eslint-disable-next-line
    .then((response) => {
      //console.info(response.data)
      return response.data
    })
    .catch((err) => this.onHandleError(err))
    .then(this.mapFalseToNull)
  }

  // eslint-disable-next-line
  onHandleError(error: any): boolean {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {

      const msg: string = error.response.data.message

      if (msg.indexOf('JWSInvalidSignature') >= 0) {        
        this.onTokenInvalid()
        return false
      }

      const urlAndMsg = (error.response.config.url || '') + ': ' + msg
      if (error.response.data.details) {
        alert(urlAndMsg + ': ' + error.response.data.details)
      }
      else if (error.response.data.hint) {
        alert(urlAndMsg + ': ' + error.response.data.hint)
      }
      else {
        alert(urlAndMsg)
      }
    }
    else {
      alert('Connect error.')
    }
    return false
  }

  // eslint-disable-next-line
  mapFalseToNull(result: any): any {
    if (result === false) { return null }
    return result
  }
}