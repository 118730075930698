<template>

  <dlg-obj typeName="Event Participant"
           :defaultWidth="550"
           :getCurrentObject="getCurrentObject"
           :saveObject="saveObject"
           :isValid="isValid"
           v-slot="{obj}"
           @focus="onFocus"
           @object="onObjectSet"
           ref="theDialog">

    <v-container>

      <v-row>
        <v-col>
          <v-autocomplete label="Contact" v-model="obj.contact_id" :items="contacts" ref="selectContact" clearable hide-details></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea     label="Notes"   v-model="obj.notes" hide-details></v-textarea>
        </v-col>
      </v-row>

    </v-container>

    <dlg-contact  ref="dlgEditContact"  :db="db"></dlg-contact>

  </dlg-obj>

</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DbVendor } from '../DbVendor'
import DlgObj from './DlgObj.vue'
import DlgContact from './DlgContact.vue'
import * as data from './../types'

interface SelectEntry {
  text: string
  value: number | null
}

@Component({
  components: {
    DlgObj,
    DlgContact,
  },
})
export default class DlgEventParticipant extends Vue {

  @Prop(Object) db!: DbVendor

  theObj: data.EventParticipant = data.getDefaultEventParticipant()

  onObjectSet(obj: data.EventParticipant): void {
    this.theObj = obj
  }

  isValid(obj: data.EventParticipant): boolean {
    return obj.contact_id !== 0 && obj.contact_id !== null
  }

  getCurrentObject(id: number): Promise<data.EventParticipant> {
    return this.db.event_participant(id)
  }

  saveObject(obj: data.EventParticipant, is_new: boolean): Promise<number | null> {
    return this.db.event_participant_write(obj, is_new)
  }

  onFocus(): void {
    const element = this.$refs.selectContact as HTMLElement
    if (element) {
      element.focus()
    }
  }

  add_new(event: data.EventRow): Promise<number | null> {
    const dlg = this.$refs.theDialog as DlgObj
    const newObj = data.getDefaultEventParticipant()
    newObj.event_id = event.id
    return dlg.add_new(newObj)
  }

  edit(id: number): Promise<boolean> {
    const dlg = this.$refs.theDialog as DlgObj
    return dlg.edit(id, data.getDefaultEventParticipant())
  }

  get contacts(): SelectEntry[] {
    const values: SelectEntry[] = this.db.contacts.map((cont) => {
      return {
        text: cont.last_name + ', ' + cont.first_name + '  ' + cont.email + (cont.retired ? ' [RETIRED]' : ''),
        value: cont.id,
      }
    })
    values.sort((a, b) => {
      return a.text.localeCompare(b.text);
    })
    const add: SelectEntry = {
      text: 'Add new...',
      value: -1,
    }
    values.unshift(add)
    return values
  }

  @Watch('theObj.contact_id')
  watch_ObjContact(newVal: number | null): void {
    if (newVal === -1) {
      this.addContact().then((idOrNull) => {
        if (idOrNull === null) {
          this.theObj.contact_id = null
          console.info('New contact failed!')
        }
        else {
          this.theObj.contact_id = idOrNull
          console.info('New contact ok: ' + idOrNull)
        }
      })
    }
  }

  async addContact(): Promise<number | null> {
    const dlgEditContact = this.$refs.dlgEditContact as DlgContact
    const idOrNull = await dlgEditContact.add_new()
    return idOrNull
  }
}

</script>
