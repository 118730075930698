import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dlg-obj',{ref:"theDialog",attrs:{"typeName":"License History","defaultWidth":750,"getCurrentObject":_vm.getCurrentObject,"saveObject":_vm.saveObject,"isValid":_vm.isValid},on:{"focus":_vm.onFocus},scopedSlots:_vm._u([{key:"default",fn:function({obj}){return [_c(VContainer,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c('edit-date',{attrs:{"label":"When","width":"8em"},model:{value:(obj.change_date),callback:function ($$v) {_vm.$set(obj, "change_date", $$v)},expression:"obj.change_date"}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtnToggle,{attrs:{"dense":""},model:{value:(obj.change_actor_id),callback:function ($$v) {_vm.$set(obj, "change_actor_id", $$v)},expression:"obj.change_actor_id"}},[_vm._l((_vm.db.all_vendor_logins().filter(it => it.can_login)),function(login){return _c(VBtn,{key:login.id,attrs:{"value":login.id}},[_vm._v(" "+_vm._s(login.id === obj.change_actor_id ? '\u2713 ' + login.login : login.login)+" ")])}),_vm._l((_vm.db.all_vendor_logins().filter(it => !it.can_login)),function(login){return _c(VBtn,{key:login.id,staticStyle:{"text-decoration":"line-through"},attrs:{"value":login.id,"color":"white"}},[_vm._v(" "+_vm._s(login.id === obj.change_actor_id ? '\u2713 ' + login.login : login.login)+" ")])})],2)],1)],1),_c(VRow,[_c(VCol,[_c(VTextarea,{ref:"txtFocusStart",attrs:{"label":"Change Comment","hide-details":""},model:{value:(obj.change_comment),callback:function ($$v) {_vm.$set(obj, "change_comment", $$v)},expression:"obj.change_comment"}})],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }