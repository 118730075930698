<template>

  <dlg-obj typeName="Ticket"
           :defaultWidth="750"
           :getCurrentObject="getCurrentObject"
           :saveObject="saveObject"
           :isValid="isValid"
           v-slot="{obj}"
           @focus="onFocus"
           @object="onObjectSet"
           ref="theDialog">

    <v-container>

      <v-row>
        <v-col cols="auto">
          <v-select     label="Type"    v-model="obj.activity_type" :items="db.enum_activitytype()" style="max-width:5em" hide-details></v-select>
        </v-col>
        <v-col>
          <v-text-field label="Subject" v-model="obj.subject" ref="txtFocusStart" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>

        <!--
          <v-btn-toggle v-model="obj.assigned_to_id" dense>

            <v-tooltip bottom v-for="login in db.all_vendor_logins().filter(it => it.can_login)" :key="login.id">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :value="login.id">
                  {{ login.id === obj.assigned_to_id ? '\u2713 ' + login.login : login.login }}
                </v-btn>
              </template>
              <span>{{ login.full_name }}</span>
            </v-tooltip>

          </v-btn-toggle>
        -->

          <v-autocomplete label="Assigned To" 
              v-model="obj.assigned_to_id" 
              :items="assignable_logins" 
              clearable style="width: 24em;" 
              hide-details>
          </v-autocomplete>
          
        <!--
          <v-btn-toggle v-model="obj.assigned_to_id" dense>

            <v-tooltip bottom v-for="login in db.all_non_vendor_logins().filter(it => it.can_login)" :key="login.id">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :value="login.id">
                  {{ login.id === obj.assigned_to_id ? '\u2713 ' + login.login : login.login }}
                </v-btn>
              </template>
              <span>{{ login.full_name + ' (' + login.vendor_name + ')'}}</span>
            </v-tooltip>
            
          </v-btn-toggle>
        -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto" >
          <v-autocomplete label="Customer" v-model="obj.customer_id" :items="customers" clearable style="width: 24em;" hide-details></v-autocomplete>
        </v-col>
        <v-col>
          <v-select       label="Contact" v-model="obj.contact_id"   :items="contacts" clearable style="width: 14em;" hide-details></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto">
          <edit-date label="Created At" v-model="obj.created_at" width="9em"></edit-date>
        </v-col>
        <v-col>
          <edit-date label="Due At" v-model="obj.due_at" width="9em"></edit-date>
        </v-col>
      </v-row>

      <v-row>

        <v-col cols="auto">
          <v-select     label="Priority" v-model="obj.priority" :items="db.enum_activitypriority()" style="max-width:6em" hide-details></v-select>
        </v-col>

        <v-col>

          <v-btn-toggle v-model="obj.status" dense class="mt-3 ml-4">
            <v-btn v-for="status in db.enum_activitystatus()" :key="status" :value="status">
              {{ status === obj.status ? '\u2713 ' + status : status }}
            </v-btn>
          </v-btn-toggle>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea   label="Notes"    v-model="obj.notes" hide-details rows="10"></v-textarea>
        </v-col>
      </v-row>

    </v-container>

    <dlg-customer ref="dlgEditCustomer" :db="db"></dlg-customer>
    <dlg-contact  ref="dlgEditContact"  :db="db"></dlg-contact>

  </dlg-obj>

</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DbVendor } from '../DbVendor'
import DlgObj from './DlgObj.vue'
import DlgCustomer from './DlgCustomer.vue'
import DlgContact from './DlgContact.vue'
import EditDate from '../components/EditDate.vue'
import * as data from './../types'

interface SelectEntry {
  text: string
  value: number | null
}

@Component({
  components: {
    DlgObj,
    DlgCustomer,
    DlgContact,
    EditDate,
  },
})
export default class DlgActivity extends Vue {

  @Prop(Object) db!: DbVendor

  theObj: data.Activity = data.getDefaultActivity()
  theContacts: data.ContactRow[] = []

  onObjectSet(obj: data.Activity): void {
    this.theObj = obj
  }

  isValid(obj: data.Activity): boolean {
    return obj.subject !== '' && obj.assigned_to_id !== null && obj.assigned_to_id !== 0
  }

  getCurrentObject(id: number): Promise<data.Activity> {
    return this.db.activity(id)
  }

  saveObject(obj: data.Activity, is_new: boolean): Promise<number | null> {
    return this.db.activity_write(obj, is_new)
  }

  onFocus(): void {
    const element = this.$refs.txtFocusStart as HTMLElement
    if (element) {
      element.focus()
    }
  }

  get assignable_logins(): SelectEntry[] {
    const thisVendor = this.db.all_vendor_logins().filter(it => it.can_login).map((it) => {
      return {
        text: it.login + ' \u2013 ' + it.full_name,
        value: it.id,
      }
    })
    const other = this.db.all_non_vendor_logins().filter(it => it.can_login).map((it) => {
      return {
        text: it.login + ' \u2013 ' + it.full_name + ' (' + it.vendor_name + ')',
        value: it.id,
      }
    })
    return [...thisVendor, ...other]    
  }

  get customers(): SelectEntry[] {
    const values: SelectEntry[] = this.db.customers.map((cust) => {
      return {
        text: cust.short_name,
        value: cust.id,
      }
    })
    values.sort((a, b) => {
      return a.text.localeCompare(b.text);
    })
    const add: SelectEntry = {
      text: 'Add new...',
      value: -1,
    }
    values.unshift(add)
    return values
  }

  get contacts(): SelectEntry[] {
    const values: SelectEntry[] = this.theContacts.map((contact) => {
      return {
        text: contact.last_name + ', ' + contact.first_name,
        value: contact.id,
      }
    })
    values.sort((a, b) => {
      return a.text.localeCompare(b.text);
    })

    if (this.theObj.customer_id !== null) {
      const add: SelectEntry = {
        text: 'Add new...',
        value: -1,
      }
      values.unshift(add)
    }
    return values
  }

  @Watch('theObj.customer_id')
  watch_ObjCustomer(newVal: number | null): void {
    // console.info('watch_ObjCustomer: ' + this.theObj.customer_id)
    this.updateContactsForCustomer()
    if (newVal === -1) {
      this.addCustomer().then((idOrNull) => {
        if (idOrNull === null) {
          this.theObj.customer_id = null
          console.info('New customer failed!')
        }
        else {
          this.theObj.customer_id = idOrNull
          console.info('New customer ok: ' + idOrNull)
        }
      })
    }
  }

  async updateContactsForCustomer(): Promise<void> {
    const customer_id = this.theObj.customer_id
    if (customer_id !== null) {
      this.theContacts = await this.db.customer_contacts(customer_id)
    }
    else {
      this.theContacts = []
    }
    const contact_id = this.theObj.contact_id
    if (contact_id !== null && !this.theContacts.some((c) => c.id === contact_id)) {
      this.theObj.contact_id = null
    }
  }

  @Watch('theObj.contact_id')
  watch_ObjContact(newVal: number | null): void {
    if (newVal === -1) {
      this.addContact().then((idOrNull) => {
        if (idOrNull === null) {
          this.theObj.contact_id = null
          console.info('New contact failed!')
        }
        else {
          this.theObj.contact_id = idOrNull
          console.info('New contact ok: ' + idOrNull)
        }
      })
    }
  }

  async addContact(): Promise<number | null> {
    if (this.theObj.customer_id === null) { return null }
    const dlgEditContact = this.$refs.dlgEditContact as DlgContact
    const idOrNull = await dlgEditContact.add_new(this.theObj.customer_id)
    if (idOrNull !== null) {
      this.updateContactsForCustomer()
    }
    return idOrNull
  }

  async addCustomer(): Promise<number | null> {
    const dlgEditCustomer = this.$refs.dlgEditCustomer as DlgCustomer
    return await dlgEditCustomer.add_new()
  }


  add_new(customer?: data.CustomerRow, contact?: data.ContactRow): Promise<number | null> {
    const dlg = this.$refs.theDialog as DlgObj
    const newObj = data.getDefaultActivity()
    if (contact !== undefined) {
      newObj.customer_id = contact.customer_id
      newObj.contact_id  = contact.id
    }
    else if (customer !== undefined) {
      newObj.customer_id = customer.id
      newObj.contact_id  = null
    }
    return dlg.add_new(newObj)
  }

  edit(id: number): Promise<boolean> {
    const dlg = this.$refs.theDialog as DlgObj
    return dlg.edit(id, data.getDefaultActivity())
  }
}

</script>
