<template>

  <dlg-obj typeName="Contact"
           :defaultWidth="620"
           :getCurrentObject="getCurrentObject"
           :saveObject="saveObject"
           :isValid="isValid"
           v-slot="{obj}"
           @focus="onFocus"
           @object="onObjectSet"
           ref="theDialog">

    <v-container>

      <v-row dense>
        <v-col>
          <v-autocomplete label="Customer" v-model="obj.customer_id" :items="customers" clearable hide-details ref="selectCustomer"></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto">
          <v-select     label="Language" v-model="obj.lang" :items="db.enum_langcode()" style="max-width:5.5em" ref="selectLang" hide-details></v-select>
        </v-col>
        <v-col cols="auto">
          <v-select     label="Gender" v-model="obj.gender" :items="db.enum_gender()" style="max-width:5.5em" hide-details></v-select>
        </v-col>
        <v-col>
          <v-text-field label="Title" v-model="obj.title" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="auto">
          <v-text-field label="First Name" v-model="obj.first_name" style="max-width:17em" ref="txtFirstName" hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Last Name" v-model="obj.last_name" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>

          <v-btn-toggle v-model="obj.contact_type" dense>
            <v-btn v-for="type in db.enum_contacttype()" :key="type" :value="type">
              {{ type === obj.contact_type ? '\u2713 ' + type : type }}
            </v-btn>
          </v-btn-toggle>

        </v-col>
      </v-row>

      <v-row dense>
        <v-col>

          <v-btn-toggle v-model="obj.mailing_state" dense>
            <v-btn v-for="state in db.enum_mailingstate()" :key="state" :value="state">
              {{ state === obj.mailing_state ? '\u2713 ' + state : state }}
            </v-btn>
          </v-btn-toggle>

          <v-text-field label="Mailing Notes" :disabled="obj.mailing_state === null || obj.mailing_state === undefined" v-model="obj.mailing_notes" hide-details></v-text-field>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field label="E-Mail" v-model="obj.email" hide-details></v-text-field>
        </v-col>
        <v-col>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox v-on="on" label="Retired" v-model="obj.retired" hide-details></v-checkbox>
              </div>
            </template>
            <span>{{ 'No longer with the company' }}</span>
          </v-tooltip>

        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-text-field label="Phone" v-model="obj.phone" hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Mobile" v-model="obj.mobile" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-select     label="Address" v-model="obj.address_id" :items="addresses" clearable hide-details></v-select>
          <!-- <p>{{obj.address_id !== null ? obj.address_id: 'null' }}</p> -->
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-textarea   label="Notes"    v-model="obj.notes" hide-details></v-textarea>
        </v-col>
      </v-row>

    </v-container>

    <dlg-customer ref="dlgEditCustomer" :db="db"></dlg-customer>
    <dlg-address ref="dlgEditAddress" :db="db"></dlg-address>

  </dlg-obj>

</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DbVendor } from '../DbVendor'
import DlgObj from './DlgObj.vue'
import DlgCustomer from './DlgCustomer.vue'
import DlgAddress from './DlgAddress.vue'
import * as data from '../types'

interface SelectEntry {
  text: string
  value: number | null
}

@Component({
  components: {
    DlgObj,
    DlgCustomer,
    DlgAddress,
  },
})
export default class DlgContact extends Vue {

  @Prop(Object) db!: DbVendor

  theObj: data.Contact = data.getDefaultContact()
  theAddresses: data.AddressRow[] = []

  onObjectSet(obj: data.Contact): void {
    this.theObj = obj
    this.updateAddressesForCustomer()
  }

  @Watch('theObj.customer_id')
  watch_ObjCustomer(newVal: number | null): void {
    // console.info('watch_ObjCustomer: ' + this.theObj.customer_id)
    this.updateAddressesForCustomer()

    if (newVal === -1) {
      this.addCustomer().then((idOrNull) => {
        if (idOrNull === null) {
          this.theObj.customer_id = null
          console.info('New customer failed!')
        }
        else {
          this.theObj.customer_id = idOrNull
          console.info('New customer ok: ' + idOrNull)
        }
      })
    }
  }

  async updateAddressesForCustomer(): Promise<void> {
    const customer_id = this.theObj.customer_id
    if (customer_id !== null) {
      this.theAddresses = await this.db.addresses_for_customer(customer_id)
    }
    else {
      this.theAddresses = []
    }
    const address_id = this.theObj.address_id
    if (address_id !== null && !this.theAddresses.some((a) => a.id === address_id)) {
      this.theObj.address_id = null
    }
  }

  @Watch('theObj.address_id')
  watch_ObjAddress(newVal: number | null): void {
    if (newVal === -1) {
      this.addAddress().then((idOrNull) => {
        if (idOrNull === null) {
          this.theObj.address_id = null
          console.info('New address failed!')
        }
        else {
          this.theObj.address_id = idOrNull
          console.info('New address ok: ' + idOrNull)
        }
      })
    }
  }

  async addAddress(): Promise<number | null> {
    if (this.theObj.customer_id === null) { return null }
    const dlgEditAddress = this.$refs.dlgEditAddress as DlgAddress
    const idOrNull = await dlgEditAddress.add_new(this.theObj.customer_id)
    if (idOrNull !== null) {
      this.updateAddressesForCustomer()
    }
    return idOrNull
  }

  async addCustomer(): Promise<number | null> {
    const dlgEditCustomer = this.$refs.dlgEditCustomer as DlgCustomer
    const idOrNull = await dlgEditCustomer.add_new()
    return idOrNull
  }

  get customers(): SelectEntry[] {
    const values: SelectEntry[] = this.db.customers.map((cust) => {
      return {
        text: cust.short_name,
        value: cust.id,
      }
    })
    values.sort((a, b) => {
      return a.text.localeCompare(b.text);
    })
    const add: SelectEntry = {
      text: 'Add new...',
      value: -1,
    }
    values.unshift(add)
    return values
  }

  get addresses(): SelectEntry[] {
    const values: SelectEntry[] = this.theAddresses.map((ad) => {
      let label = ''
      if (ad.street2 != null && ad.street2 !== '') {
        label = ad.street1 + ', ' + ad.street2 + ', ' + ad.postal_code + ' ' + ad.town
      }
      else {
        label = ad.street1 + ', ' + ad.postal_code + ' ' + ad.town
      }
      return {
        text: label,
        value: ad.id,
      }
    })
    values.sort((a, b) => {
      return a.text.localeCompare(b.text);
    })
    const add: SelectEntry = {
      text: 'Add new...',
      value: -1,
    }
    values.unshift(add)
    return values
  }

  isValid(obj: data.Contact): boolean {
    return obj.last_name !== ''&& obj.customer_id !== null && obj.customer_id > 0 && obj.lang !== ''
  }

  getCurrentObject(id: number): Promise<data.Contact> {
    return this.db.contact(id)
  }

  saveObject(obj: data.Contact, is_new: boolean): Promise<number | null> {
    return this.db.contact_write(obj, is_new)
  }

  onFocus(): void {
    if (this.theObj.customer_id === 0 || this.theObj.customer_id === null) {
      const element = this.$refs.selectCustomer as HTMLElement
      if (element) {
        element.focus()
      }
    }
    else if (this.theObj.lang === '') {
      const element = this.$refs.selectLang as HTMLElement
      if (element) {
        element.focus()
      }
    }
    else {
      const element = this.$refs.txtFirstName as HTMLElement
      if (element) {
        element.focus()
      }
    }
  }

  add_new(customer_id?: number): Promise<number | null> {
    const dlg = this.$refs.theDialog as DlgObj
    const newObj = data.getDefaultContact()
    const id = customer_id || 0
    if (id !== null && id !== 0) {
      const customer = this.db.customers.find((cust) => cust.id === customer_id)
      if (customer) {
        newObj.lang = customer.lang
      }
    }
    newObj.customer_id = id
    return dlg.add_new(newObj)
  }

  edit(id: number): Promise<boolean> {
    const dlg = this.$refs.theDialog as DlgObj
    return dlg.edit(id, data.getDefaultContact())
  }
}

</script>
