<template>
  <div @keydown.esc="onEscape">
    <v-data-table
      :no-data-text="noDataText"
      :headers="headers"
      :items="filteredItems"
      multi-sort
      item-key="id"
      :footer-props="footer"
      show-expand
      :expanded.sync="expanded"
      class="elevation-4 mt-2">

      <template v-slot:top>
        <v-container>
          <v-row>
            <v-col md="auto">

              <v-checkbox
                v-model="showClosed"
                label="Show closed">
              </v-checkbox>

            </v-col>
            <v-col>

              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
                clearable>
              </v-text-field>

            </v-col>

            <v-col cols="auto" class="mt-5 mr-3">
              <v-btn small text @click="dump()"><v-icon>cloud_download</v-icon></v-btn>
            </v-col>

            <v-col cols="auto" class="mt-5 mr-3">
              <v-btn small color="primary" @click="addItem()"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>

          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <span class="clickable" @click="editItem(item)">{{ item.id }}</span>
      </template>

      <template v-slot:[`item.last_updated`]="{ item }">
        {{ item.last_updated.substring(0, 10) }}
      </template>

      <template v-slot:[`item.subject`]="{ item }">
        <span class="clickable" @click="editItem(item)">{{ item.subject }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-card :color="statusColor(item)"> {{ item.status }} </v-card>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">

          <activity
            v-if="isExpanded(item)"
            :activity="item"
            :db="db"></activity>

        </td>
      </template>

    </v-data-table>
    <dlg-activity ref="dlgEditActivity" :db="db"></dlg-activity>
  </div>
</template>

<script lang="ts">

import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import * as data from './../types'
import * as constants from './../constants'
import { DbVendor } from '../DbVendor'
import Activity from './Activity.vue'
import DlgActivity from '../dialogs/DlgActivity.vue'

@Component({
  components: {
    Activity,
    DlgActivity,
  },
})
export default class TableActivity extends Vue {

  @Prop(Object) db!: DbVendor

  search = ''
  noDataText = 'No customers'
  footer = {
    showFirstLastPage: true,
    itemsPerPageOptions: [15, 50, 100, { text: 'All', value: -1 }],
  }
  headers = [
    { text: 'ID',       align: 'left', sortable: true, value: 'id'  },
    { text: 'Created',  align: 'left', sortable: true, value: 'created_at'   },
    { text: 'Updated',  align: 'left', sortable: true, value: 'last_updated' },
    { text: 'Subject',  align: 'left', sortable: true, value: 'subject'      },
    { text: 'Status',   align: 'left', sortable: true, value: 'status',   sort: this.sortStatus   },
    { text: 'Prio',     align: 'left', sortable: true, value: 'priority', sort: this.sortPriority },
    { text: 'User',     align: 'left', sortable: true, value: 'assigned_to'  },
    { text: 'Customer', align: 'left', sortable: true, value: 'customer'     },
    { text: 'Contact',  align: 'left', sortable: true, value: 'contact'      },
    { text: 'Due',      align: 'left', sortable: true, value: 'due_at'       },
    { text: '',                                        value: 'data-table-expand' },
  ]
  showClosed = false
  expanded: data.ActivityRow[] = []

  urlSelect = false

  mounted(): void {
    const params = (new URL(window.location.toString())).searchParams;
    const tab = params.get('tab')
    const search = params.get('search')
    if (tab === 'tickets' && search !== null) {
      this.search = search
      this.urlSelect = true
    }
  }

  @Watch('filteredItems')
  watch_filteredItems(): void {
    const items = this.filteredItems
    if (items.length === 1 && this.urlSelect) {
      this.expanded = [items[0]]
      this.urlSelect = false
    }
  }
  
  get filteredItems(): data.ActivityRow[] {
    if (this.showClosed) return this.filteredItems0
    return this.filteredItems0.filter((act) => act.status !== 'Closed')
  }

  get filteredItems0(): data.ActivityRow[] {

    const search = (this.search || '').toLowerCase()
    const words = search.split(' ').filter((w) => w !== '')
    const posWords = words.filter((w) => !w.startsWith('-'))
    const negWords = words.filter((w) => w.startsWith('-')).map((w) => w.substring(1)).filter((s) => s.length > 0)

    return this.db.activities.filter((item) => {
      const valLower = (item.id + ' ' +
                        item.subject + ' ' +
                        item.customer + ' ' +
                        item.contact + ' ' +
                        item.assigned_to + ' ' +
                        item.status + ' ' +
                        item.priority).toLowerCase()
      return posWords.every((word) => valLower.indexOf(word) !== -1) &&
             negWords.every((word) => valLower.indexOf(word) === -1)
    })
  }

  statusColor(activity: data.ActivityRow): string {
    switch (activity.status) {
      case 'ActionRequired': return data.activity_IsDue(activity) ? constants.ColorWarn2 : constants.ColorWarn1
      case 'Closed':         return constants.ColorNormal
      case 'Wait':           return constants.ColorOutdated
      default:               return constants.ColorNormal
    }
  }

  isExpanded(activity: data.ActivityRow): boolean {
    return this.expanded.findIndex((c) => c.id === activity.id) > -1
  }

  onEscape(): void {
    this.expanded.splice(0)
  }

  sortPriority(strA: string, strB: string): number {

    const f = (a: string) => {
      switch(a) {
        case 'Normal': return 1;
        case 'High': return 0;
        default: return 2;
      }
    }

    const a = f(strA)
    const b = f(strB)
    return a - b
  }

  sortStatus(strA: string, strB: string): number {

    const f = (a: string) => {
      switch(a) {
        case 'Wait': return 1;
        case 'ActionRequired': return 0;
        default: return 2;
      }
    }

    const a = f(strA)
    const b = f(strB)
    return a - b
  }

  async addItem(): Promise<void> {
    const dlgEditActivity = this.$refs.dlgEditActivity as DlgActivity
    await dlgEditActivity.add_new()
  }

  async editItem(event: data.EventRow): Promise<void> {
    const dlgEditActivity = this.$refs.dlgEditActivity as DlgActivity
    await dlgEditActivity.edit(event.id)
  }

  async dump(): Promise<void> {
    await this.db.dumpTickets()
  }
}

</script>

