import { ApiVendor } from './api'
import * as data from './types'

// eslint-disable-next-line
type AnyRow = { [k: string]: any }

export class DbVendor {

  private api: ApiVendor

  activities: data.ActivityRow[] = []
  customers: data.CustomerRow[] = []
  contacts: data.ContactRow[] = []
  licenses: data.LicenseRow[] = []
  events: data.EventRow[] = []

  dataCompletedPromise: Promise<void> | null = null

  constructor(vendor?: number, token?: string, onTokenInvalid?: () => void) {
    if (vendor !== undefined && token !== undefined) {
      this.api = new ApiVendor(vendor, token, onTokenInvalid)
      this.onLoadAllData()
    }
    else {
      this.api = new ApiVendor(0)
    }
  }

  private async onLoadAllData(): Promise<void> {

    let dataLoadResolve: (() => void) = () => { /* */ }

    this.dataCompletedPromise = new Promise<void>((dataCompletedResolve) => {
      dataLoadResolve = dataCompletedResolve
    })

    const api = this.api
    this.activities = await api.activities()
    this.customers = await api.customers()
    this.contacts = await api.contacts()
    this.licenses = await api.licenses()
    this.events = await api.events()
    dataLoadResolve()
  }

  get the_vendor(): number {
    return this.api.the_vendor
  }

  async refreshActivities(): Promise<void> {
    this.activities = await this.api.activities()
  }

  async refreshCustomers(): Promise<void> {
    this.customers = await this.api.customers()
  }

  async refreshContacts(): Promise<void> {
    this.contacts = await this.api.contacts()
  }

  async refreshLicenses(): Promise<void> {
    this.licenses = await this.api.licenses()
  }

  async refreshEvents(): Promise<void> {
    this.events = await this.api.events()
  }

  activity_notes(activity_id: number): Promise<string> {
    return this.api.activity_notes(activity_id)
  }

  activity_items(activity_id: number): Promise<data.ActivityItemRow[]> {
    return this.api.activity_items(activity_id)
  }

  activity_item(activity_item_id: number): Promise<data.ActivityItem> {
    return this.api.activity_item(activity_item_id)
  }

  activity_item_write(data: data.ActivityItem, is_new: boolean): Promise<number | null> {
    return this.api.activity_item_write(data, is_new)
  }

  async customer_contacts(customer_id: number): Promise<data.ContactRow[]> {
    await this.dataCompletedPromise
    return this.contacts.filter((con) => con.customer_id === customer_id)
  }

  addresses_for_customer(customer_id: number): Promise<data.AddressRow[]> {
    return this.api.addresses_for_customer(customer_id)
  }

  async customer_licenses(customer_id: number): Promise<data.LicenseRow[]> {
    await this.dataCompletedPromise
    return this.licenses.filter((lic) => lic.customer_id === customer_id)
  }

  async contact_licenses(contact_id: number): Promise<data.LicenseRow[]> {
    await this.dataCompletedPromise
    return this.licenses.filter((lic) => lic.contact_id === contact_id)
  }

  async customer_activities(customer_id: number): Promise<data.ActivityRow[]> {
    await this.dataCompletedPromise
    const res = this.activities.filter((act) => act.customer_id === customer_id)
    return res.sort((a, b) => -1 * a.created_at.localeCompare(b.created_at))
  }

  async contact_activities(contact_id: number): Promise<data.ActivityRow[]> {
    await this.dataCompletedPromise
    const res = this.activities.filter((act) => act.contact_id === contact_id)
    return res.sort((a, b) => -1 * a.created_at.localeCompare(b.created_at))
  }

  events_for_customer(customer_id: number): Promise<data.EventRow[]> {
    return this.api.events_for_customer(customer_id)
  }

  events_for_contact(contact_id: number): Promise<data.EventRow[]> {
    return this.api.events_for_contact(contact_id)
  }

  license_history(license_id: number): Promise<data.LicenseHistory[]> {
    return this.api.license_history(license_id)
  }

  license_history_meta(license_history_id: number): Promise<data.LicenseHistoryMeta> {
    return this.api.license_history_meta(license_history_id)
  }

  license_history_meta_write(data: data.LicenseHistoryMeta): Promise<number | null> {
    return this.api.license_history_meta_write(data)
  }

  event_participants(event_id: number): Promise<data.EventParticipantRow[]> {
    return this.api.event_participants(event_id)
  }

  customer(id: number): Promise<data.Customer> {
    return this.api.customer(id)
  }

  async customer_write(data: data.Customer, is_new: boolean): Promise<number | null> {
    const id_or_null = await this.api.customer_write(data, is_new)
    if (id_or_null !== null) {
      await this.refreshCustomers()
    }
    return id_or_null
  }

  activity(id: number): Promise<data.Activity> {
    return this.api.activity(id)
  }

  async activity_write(data: data.Activity, is_new: boolean): Promise<number | null> {
    const id_or_null = await this.api.activity_write(data, is_new)
    if (id_or_null !== null) {
      await this.refreshActivities()
    }
    return id_or_null
  }

  contact(id: number): Promise<data.Contact> {
    return this.api.contact(id)
  }

  async contact_write(data: data.Contact, is_new: boolean): Promise<number | null> {
    const id_or_null = await this.api.contact_write(data, is_new)
    if (id_or_null !== null) {
      await this.refreshContacts()
    }
    return id_or_null
  }

  license(id: number): Promise<data.License> {
    return this.api.license(id)
  }

  async license_write(data: data.License, is_new: boolean, dataOriginal: data.License): Promise<number | null> {

    const notes = (data.notes || '').trim()
    const idx = notes.indexOf('\n')
    let change_comment = (idx > 0) ? notes.substring(0, idx).trim() : notes

    if (data.disabled && !dataOriginal.disabled && data.disabled_note.trim() !== '') {
      change_comment = data.disabled_note.trim()
    }

    const orig2: data.License = JSON.parse(JSON.stringify(dataOriginal))
    const new2: data.License  = JSON.parse(JSON.stringify(data))
    orig2.notes = ''
    new2.notes = ''
    const onlyNotesChanged = JSON.stringify(orig2) === JSON.stringify(new2)
    const addToHistory = !onlyNotesChanged

    const id_or_null = await this.api.license_write(data, is_new, change_comment, addToHistory)
    if (id_or_null !== null) {
      await this.refreshLicenses()
    }
    return id_or_null
  }

  address(id: number): Promise<data.Address> {
    return this.api.address(id)
  }

  address_write(data: data.Address, is_new: boolean): Promise<number | null> {
    return this.api.address_write(data, is_new)
  }

  event(id: number): Promise<data.Event> {
    return this.api.event(id)
  }

  async event_write(data: data.Event, is_new: boolean): Promise<number | null> {
    const id_or_null = await this.api.event_write(data, is_new)
    if (id_or_null !== null) {
      await this.refreshEvents()
    }
    return id_or_null
  }

  event_participant(id: number): Promise<data.EventParticipant> {
    return this.api.event_participant(id)
  }

  async event_participant_write(data: data.EventParticipant, is_new: boolean): Promise<number | null> {
    const id_or_null = await this.api.event_participant_write(data, is_new)
    if (id_or_null !== null) {
      await this.refreshEvents()
    }
    return id_or_null
  }

  get_change_log(): Promise<data.ChangeLogEntry[]> {
    return this.api.get_change_log()
  }

  all_vendor_logins(): data.VendorLogin[] {
    return this.api.all_vendor_logins()
  }

  all_logins(): data.VendorLogin[] {
    return this.api.all_logins()
  }

  all_non_vendor_logins(): data.VendorLogin[] {
    const thisVendor = this.the_vendor
    return this.api.all_logins().filter(log => log.vendor_id !== thisVendor)
  }

  all_apps(): data.App[] {
    return this.api.all_apps()
  }

  all_app_versions(): data.AppVersion[] {
    return this.api.all_app_versions()
  }

  versions_for_app(app_id: number): data.AppVersion[] {
    return this.api.versions_for_app(app_id)
  }

  createLicenseFile(license_id: number): Promise<data.LicenseFile | null> {
    return this.api.createLicenseFile(license_id)
  }

  dumpCustomers(): Promise<void> {
    return this.dumpTable('Customer', this.customers)
  }
  
  getChristmasList(): Promise<data.ChristmasListEntry[]> {
    return this.api.contactsChristmas()
  }

  async dumpContactsChristmas(): Promise<void> {
    const contacts = await this.api.contactsChristmas()
    for (const contact of contacts) { // eslint-disable-next-line
      const contactAny: any = contact
      contactAny.id = undefined
      contactAny.mailing = data.mapMailing2Bool(contact.mailing)
    }
    return this.dumpTable('Christmas', contacts)
  }

  async dumpContacts(): Promise<void> {
    const contacts = await this.api.contacts()
    for (const contact of contacts) { // eslint-disable-next-line
      const contactAny: any = contact
      contactAny.id = undefined
      contactAny.mailing = data.mapMailing2Bool(contact.mailing)
    }
    return this.dumpTable('Contact', contacts)
  }

  dumpLicenses(): Promise<void> {
    return this.dumpTable('License', this.licenses)
  }

  dumpEvents(): Promise<void> {
    return this.dumpTable('Event', this.events)
  }

  dumpTickets(): Promise<void> {
    return this.dumpTable('Ticket', this.activities)
  }

  async dumpTable(label: string, entries: AnyRow[]): Promise<void> {
    const content = await this.api.dumpTable(label, JSON.stringify(entries))
    if (content) {
      const blob = new Blob([content], { type: 'application/octet-stream' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = label + '.xlsx'
      link.click()
      URL.revokeObjectURL(link.href)
    }
    else {
      console.info('Blob res = null!')
    }
  }

  private get_enumvalues(the_enum: string): string[] {
    return this.api.all_enums().filter((ev) => ev.enum === the_enum).map((ev) => ev.value)
  }

  enum_langcode(): string[] {
    return this.get_enumvalues('langcode')
  }

  enum_customertype(): string[] {
    return this.get_enumvalues('customertype')
  }

  enum_addrtype(): string[] {
    return this.get_enumvalues('addrtype')
  }

  enum_gender(): string[] {
    return this.get_enumvalues('gender')
  }

  enum_contacttype(): string[] {
    return this.get_enumvalues('contacttype')
  }

  enum_mailingstate(): string[] {
    return this.get_enumvalues('mailingstate')
  }

  enum_usagetype(): string[] {
    return this.get_enumvalues('usagetype')
  }

  enum_lictype(): string[] {
    return this.get_enumvalues('lictype')
  }

  enum_academic(): string[] {
    return this.get_enumvalues('academic')
  }

  enum_protect(): string[] {
    return this.get_enumvalues('protect')
  }

  enum_activitystatus(): string[] {
    return this.get_enumvalues('activitystatus')
  }

  enum_activitytype(): string[] {
    return this.get_enumvalues('activitytype')
  }

  enum_activitypriority(): string[] {
    return this.get_enumvalues('activitypriority')
  }

}