<template>
  <div @keydown.esc="onEscape">
    <v-data-table
      :no-data-text="noDataText"
      :headers="headers"
      :items="filteredItems"
      item-key="id"
      :footer-props="footer"
      show-expand
      :expanded.sync="expanded"
      class="elevation-4 mt-2">

      <template v-slot:top>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field v-model="search" label="Search" clearable class="mx-4"></v-text-field>
            </v-col>
            <v-col cols="auto" class="mt-5 mr-3">
              <v-btn small text @click="dump()"><v-icon>cloud_download</v-icon></v-btn>
            </v-col>
            <v-col cols="auto" class="mt-5 mr-3">
              <v-btn small color="primary" @click="addItem()"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.short_name`]="{ item }">
        <span class="clickable" @click="editItem(item)">{{ item.short_name }}</span>
      </template>

      <template v-slot:[`item.license_count`]="{ item }">
        <div v-if="item.license_count > 0" class="clickable" @click="dumpLicenses(item)">
          <v-icon  class="mr-4">cloud_download</v-icon><span>{{ item.license_count }}</span>
        </div>
        <div v-else>0</div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">

          <v-container>

            <v-row>
              <v-col cols="auto">
                <related-contacts  v-if="isExpanded(item)" :db="db" :customer="item"></related-contacts>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <related-addresses v-if="isExpanded(item)" :db="db" :customer="item"></related-addresses>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <related-licenses  v-if="isExpanded(item)" :db="db" :customer="item" :hideCustomer="true"></related-licenses>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <related-activities v-if="isExpanded(item)" :db="db" :customer="item"></related-activities>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <related-events     v-if="isExpanded(item)" :db="db" :customer="item"></related-events>
              </v-col>
            </v-row>

          </v-container>

        </td>
      </template>

    </v-data-table>
    <dlg-customer ref="dlgEditCustomer" :db="db"></dlg-customer>
  </div>
</template>

<script lang="ts">

import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import * as data from './../types'
import RelatedLicenses from './RelatedLicenses.vue'
import RelatedContacts from './RelatedContacts.vue'
import RelatedEvents from './RelatedEvents.vue'
import RelatedAddresses from './RelatedAddresses.vue'
import RelatedActivities from './RelatedActivities.vue'
import { DbVendor } from '../DbVendor'
import DlgCustomer from '../dialogs/DlgCustomer.vue'

@Component({
  components: {
    RelatedLicenses,
    RelatedContacts,
    RelatedEvents,
    RelatedAddresses,
    RelatedActivities,
    DlgCustomer,
  },
})
export default class TableCustomer extends Vue {

  @Prop(Object) db!: DbVendor

  search = ''
  noDataText = 'No customers'
  footer = {
    showFirstLastPage: true,
    itemsPerPageOptions: [15, 50, 100, { text: 'All', value: -1 }],
  }
  headers = [
    { text: 'Domain',   align: 'right', sortable: true, value: 'domain_name' },
    { text: 'Type',     align: 'left',  sortable: true, value: 'type_customer' },
    { text: 'Name',     align: 'left',  sortable: true, value: 'short_name' },
    { text: 'Lang',     align: 'left',  sortable: true, value: 'lang' },
    { text: 'Web',      align: 'left',  sortable: true, value: 'www' },
    { text: 'Licenses', align: 'right', sortable: true, value: 'license_count' },
    { text: '',                                         value: 'data-table-expand' },
  ]
  expanded: data.CustomerRow[] = []

  urlSelect = false

  mounted(): void {
    const params = (new URL(window.location.toString())).searchParams;
    const tab = params.get('tab')
    const search = params.get('search')
    if (tab === 'customers' && search !== null) {
      this.search = search
      this.urlSelect = true
    }
  }

  @Watch('filteredItems')
  watch_filteredItems(): void {
    const items = this.filteredItems
    if (items.length === 1 && this.urlSelect) {
      this.expanded = [items[0]]
      this.urlSelect = false
    }
  }

  get filteredItems(): data.CustomerRow[] {

    const search = (this.search || '').toLowerCase()
    const words = search.split(' ').filter((w) => w !== '')
    const posWords = words.filter((w) => !w.startsWith('-'))
    const negWords = words.filter((w) => w.startsWith('-')).map((w) => w.substring(1)).filter((s) => s.length > 0)

    return this.db.customers.filter((item) => {
      const valLower = (item.domain_name + ' ' +
                        item.short_name + ' ' +
                        item.full_name + ' ' +
                        item.www).toLowerCase()
      return posWords.every((word) => valLower.indexOf(word) !== -1) &&
             negWords.every((word) => valLower.indexOf(word) === -1)
    })
  }

  isExpanded(customer: data.CustomerRow): boolean {
    return this.expanded.findIndex((c) => c.id === customer.id) > -1
  }

  onEscape(): void {
    this.expanded.splice(0)
  }

  async addItem(): Promise<void> {
    const dlgEditCustomer = this.$refs.dlgEditCustomer as DlgCustomer
    await dlgEditCustomer.add_new()
  }

  async editItem(customer: data.CustomerRow): Promise<void> {
    const dlgEditCustomer = this.$refs.dlgEditCustomer as DlgCustomer
    await dlgEditCustomer.edit(customer.id)
  }

  async dumpLicenses(customer: data.CustomerRow): Promise<void> {
    const lics = await this.db.customer_licenses(customer.id)
    for (let lic of lics) { // eslint-disable-next-line
      const licAny: any = lic
      licAny.id = undefined
      licAny.notes = undefined
    }
    await this.db.dumpTable('Licenses', lics)
    console.info(lics)
  }

  async dump(): Promise<void> {
    await this.db.dumpCustomers()
  }
}

</script>

