import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dlg-obj',{ref:"theDialog",attrs:{"typeName":"Event","defaultWidth":550,"getCurrentObject":_vm.getCurrentObject,"saveObject":_vm.saveObject,"isValid":_vm.isValid},on:{"focus":_vm.onFocus},scopedSlots:_vm._u([{key:"default",fn:function({obj}){return [_c(VContainer,[_c(VRow,[_c(VCol,[_c(VTextField,{ref:"txtFocusStart",attrs:{"label":"Event name","hide-details":""},model:{value:(obj.event_name),callback:function ($$v) {_vm.$set(obj, "event_name", $$v)},expression:"obj.event_name"}})],1)],1),_c(VRow,[_c(VCol,[_c('edit-date',{attrs:{"label":"Event date"},model:{value:(obj.event_date),callback:function ($$v) {_vm.$set(obj, "event_date", $$v)},expression:"obj.event_date"}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Event Location","hide-details":""},model:{value:(obj.event_location),callback:function ($$v) {_vm.$set(obj, "event_location", $$v)},expression:"obj.event_location"}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextarea,{attrs:{"label":"Notes","hide-details":""},model:{value:(obj.notes),callback:function ($$v) {_vm.$set(obj, "notes", $$v)},expression:"obj.notes"}})],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }