<template>

  <v-dialog v-model="dialog" @keydown.esc="cancel" persistent fullscreen>
    <v-card>

      <v-toolbar dark color="primary">
        <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Christmas Card List</v-toolbar-title>
      </v-toolbar>

      <v-card-text>        
        <v-data-table            
            :no-data-text="noDataText"
            :headers="headers"
            :items="filteredItems"
            :footer-props="footer"
            item-key="id"
            class="elevation-4 mt-2">

            <template v-slot:top>
              <v-container>
                <v-row>
                    <v-col>
                        <v-text-field v-model="search" label="Search" clearable class="mx-4"></v-text-field>
                    </v-col>
                    <v-col cols="auto" class="mt-5 mr-3">
                        <v-btn small text @click="dump()"><v-icon>cloud_download</v-icon></v-btn>
                    </v-col>
                </v-row>
              </v-container>
            </template>

            <template v-slot:[`item.last_name`]="{ item }">
              <span class="clickable" @click="editContact(item)">
                {{ item.last_name }}
              </span>
            </template>

            <template v-slot:[`item.street`]="{ item }">
              <p class="mb-0" v-for="it in formatAddress(item)" :key="it">
                {{ it }}
              </p>
            </template>

             <template v-slot:[`item.customer_full`]="{ item }">
              <p class="mb-0 clickable" v-for="it in formatFullName(item)" :key="it" @click="editCustomer(item)">
                {{ it }}
              </p>
            </template>

            <template v-slot:[`item.icon`]="{ item }">
              <v-icon @click="openCustomerList(item)">open_in_new</v-icon>
            </template>

        </v-data-table>
      </v-card-text>      
    </v-card>
    <dlg-customer ref="dlgEditCustomer" :db="db"></dlg-customer>
    <dlg-contact ref="dlgEditContact" :db="db"></dlg-contact>
  </v-dialog>

</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator'
import * as data from './../types'
import { DbVendor } from '../DbVendor'
import DlgCustomer from '../dialogs/DlgCustomer.vue'
import DlgContact from '../dialogs/DlgContact.vue'

@Component({
  components: {
    DlgCustomer,
    DlgContact,
  },
})
export default class DlgChristmas extends Vue {

  @Prop(Object) db!: DbVendor

  dialog = false

  search = ''
  noDataText = 'No changes'
  footer = {
    showFirstLastPage: true,
    itemsPerPageOptions: [10, 50, 100, { text: 'All', value: -1 }],
  }
  headers = [
    { text: '#',                   align: 'right', sortable: false, value: 'num' },  
    { text: 'Contact',             align: 'left',  sortable: false, value: 'last_name' },
    { text: 'Address',             align: 'left',  sortable: false, value: 'street' },
    { text: 'Customer Full Name',  align: 'left',  sortable: false, value: 'customer_full' },
    { text: '',                    align: 'left',  sortable: false, value: 'icon'       },
  ]

  items: data.ChristmasListEntry[] = []

  get filteredItems(): data.ChristmasListEntry[] {

    const search = (this.search || '').toLowerCase()
    const words = search.split(' ').filter((w) => w !== '')
    const posWords = words.filter((w) => !w.startsWith('-'))
    const negWords = words.filter((w) => w.startsWith('-')).map((w) => w.substring(1)).filter((s) => s.length > 0)
    const norm = (x: string|null|undefined) => {
      if (x) { return x }
      return ''
    }

    return this.items.filter((item) => {
      const valLower = (norm(item.first_name) + ' ' +
                        norm(item.last_name) + ' ' +
                        norm(item.customer) + ' ' +
                        norm(item.street1) + ' ' +
                        norm(item.street2) + ' ' +
                        norm(item.postal) + ' ' +
                        norm(item.town) + ' ' +
                        norm(item.customer_full) + ' ' +
                        norm(item.customer_full_ext1) + ' ' +
                        norm(item.customer_full_ext2) + ' ' +
                        norm(item.customer_full_ext3)).toLowerCase()
      return posWords.every((word) => valLower.indexOf(word) !== -1) &&
             negWords.every((word) => valLower.indexOf(word) === -1)
    })
  }

  formatAddress(it: data.ChristmasListEntry): string[] {
    const arr = [ ]
    if (it.street1 != null && it.street1 !== '') {
      arr.push(it.street1)
    }
    if (it.street2 != null && it.street2 !== '') {
      arr.push(it.street2)
    }
    if (it.postal !== null && it.postal !== '' && it.town !== null && it.town !== '') {
      arr.push(it.postal + ' ' + it.town)
    }
    return arr
  }

  formatFullName(it: data.ChristmasListEntry): string[] {
    const arr = [ it.customer_full ]
    if (it.customer_full_ext1 !== '') {
      arr.push(it.customer_full_ext1)
    }
    if (it.customer_full_ext2 !== '') {
      arr.push(it.customer_full_ext2)
    }
    if (it.customer_full_ext3 !== '') {
      arr.push(it.customer_full_ext3)
    }
    return arr
  }

  async editCustomer(it: data.ChristmasListEntry): Promise<void> {
    const dlgEditCustomer = this.$refs.dlgEditCustomer as DlgCustomer
    const updated = await dlgEditCustomer.edit(it.customer_id)
    if (updated) {
      await this.updateItems()
    }
  }
  
  async editContact(it: data.ChristmasListEntry): Promise<void> {
    const dlgEditContact = this.$refs.dlgEditContact as DlgContact
    const updated = await dlgEditContact.edit(it.id)
    if (updated) {
      await this.updateItems()
    }
  }

  async show(): Promise<void> {
    this.dialog = true
    await this.updateItems()
  }

  async updateItems(): Promise<void> {
    this.items = await this.db.getChristmasList()
    let i = 1
    for (const it of this.items) {
      it.num = i++
    }
  }

  openCustomerList(it: data.ChristmasListEntry): void {
    const url = 'https://simba-license.com?tab=customers&search=' + encodeURIComponent(it.customer)
    window.open(url, "_blank")
  }

  cancel(): void {
    this.dialog = false
  }

  async dump(): Promise<void> {
    await this.db.dumpTable('Christmas', this.items)
  }

}

</script>
