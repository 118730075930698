<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="Items"
      no-data-text="No license history"
      item-key="id"
      dense
      hide-default-footer
      :footer-props="footer"
      show-expand
      :expanded.sync="expanded"
      @click:row="editItem"
      :item-class="() => 'clickable'"
      class="elevation-1">

      <template v-slot:[`item.icon`]>
        <v-icon>history</v-icon>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">

          <v-container>
            <v-row>
              <v-col cols="auto">
                <p style="font-size: 1.0em;" v-html="replaceLineBreaks(item.notes)"></p>
              </v-col>
            </v-row>
          </v-container>

        </td>
      </template>

    </v-data-table>
    <dlg-license-history ref="dlgEditLicenseHistory" :db="db"></dlg-license-history>
  </div>
</template>

<script lang="ts">

import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import * as data from '../types'
import { DbVendor } from '../DbVendor'
import DlgLicenseHistory from '../dialogs/DlgLicenseHistory.vue'

@Component({
  components: {
    DlgLicenseHistory,
  },
})
export default class LicenseHistory extends Vue {

  @Prop(Object) license!: data.LicenseRow
  @Prop(Object) db!: DbVendor

  expanded: data.LicenseHistory[] = []

  footer = {
    showFirstLastPage: false,
    itemsPerPageOptions: [5000],
  }

  get headers(): Record<string, unknown>[] {

    const icon     = { text: '',         align: 'left',  sortable: false, value: 'icon'           }
    const date     = { text: 'Date',     align: 'left',  sortable: true,  value: 'change_date'   }
    const who      = { text: 'Who',      align: 'left',  sortable: true,  value: 'change_actor'  }
    const number   = { text: 'Number',   align: 'left',  sortable: true,  value: 'lic_number'   }
    const version  = { text: 'Ver',      align: 'left',  sortable: true,  value: 'app_version'  }
    const customer = { text: 'Customer', align: 'left',  sortable: true,  value: 'customer'     }
    const contact  = { text: 'Contact',  align: 'left',  sortable: true,  value: 'contact'      }
    const type     = { text: 'Type',     align: 'left',  sortable: true,  value: 'lic_type'     }
    const features = { text: 'Features', align: 'left',  sortable: true,  value: 'features'     }
    const academic = { text: 'Academic', align: 'left',  sortable: true,  value: 'academic'     }
    const lock     = { text: 'Lock',     align: 'left',  sortable: true,  value: 'protection'   }
    const valid    = { text: 'Valid',    align: 'left',  sortable: true,  value: 'valid_until'  }
    const updates  = { text: 'Updates',  align: 'left',  sortable: true,  value: 'updates_until'  }
    const disabled = { text: 'Disabled', align: 'left',  sortable: true,  value: 'disabled'     }
    const seats    = { text: 'Seats',    align: 'left',  sortable: true,  value: 'seats'        }
    const hardware = { text: 'Hardware', align: 'left',  sortable: true,  value: 'hardware'     }
    const remark   = { text: 'Remark',   align: 'left',  sortable: true,  value: 'remark'       }
    const comment  = { text: 'Change Comment', align: 'left',  sortable: false, value: 'change_comment'  }
    const expander = { text: '',         align: 'left',  sortable: false, value: 'data-table-expand' }

    const res = [
      icon,
      date,
    ]

    const items: data.LicenseHistory[] = this.items
    const showAll = items.length === 1

    if ((showAll && items[0].change_actor !== null) || items.some((it) => it.change_actor !== null)) {
      res.push(who)
    }

    if (showAll || new Set(items.map((it) => it.lic_number)).size > 1) {
      res.push(number)
    }

    if (showAll || new Set(items.map((it) => it.app_version)).size > 1) {
      res.push(version)
    }

    if (showAll || new Set(items.map((it) => it.customer_id)).size > 1) {
      res.push(customer)
    }

    if (showAll || new Set(items.map((it) => it.contact_id)).size > 1) {
      res.push(contact)
    }

    if (showAll || new Set(items.map((it) => it.lic_type)).size > 1) {
      res.push(type)
    }

    if (showAll || new Set(items.map((it) => it.features)).size > 1) {
      res.push(features)
    }

    if (showAll || new Set(items.map((it) => it.academic)).size > 1) {
      res.push(academic)
    }

    if (showAll || new Set(items.map((it) => it.protection)).size > 1) {
      res.push(lock)
    }

    if (showAll && items[0].updates_until !== null || new Set(items.map((it) => it.updates_until)).size > 1) {
      res.push(updates)
    }

    if (showAll || new Set(items.map((it) => it.valid_until)).size > 1) {
      res.push(valid)
    }

    if ((showAll && items[0].seats !== 0) || new Set(items.map((it) => it.seats)).size > 1) {
      res.push(seats)
    }

    if ((showAll && items[0].hardware !== '') || new Set(items.map((it) => it.hardware)).size > 1) {
      res.push(hardware)
    }

    if (new Set(items.map((it) => it.remark)).size > 1) {
      res.push(remark)
    }

    if (items.some((it) => it.disabled)) {
      res.push(disabled)
    }

    if (items.some((it) => it.change_comment !== '')) {
      res.push(comment)
    }

    res.push(expander)

    return res
  }

  items: data.LicenseHistory[] = []

  get Items(): data.LicenseHistory[] {

    const shorten = (s: string) => {
      let res = s.trim().substring(0, 200)
      const idx = res.indexOf('\n')
      if (idx > 0) {
        res = res.substring(0, idx)
      }
      return res
    }

    return this.items.map((it) => {
      return {
        ...it,
        notes_short: shorten(it.change_comment)
      }
    })
  }

  mounted(): void {
    this.onLoad()
  }

  @Watch('license')
  watch_License(): void {
    this.onLoad()
  }

  async onLoad(): Promise<void> {
    this.items = await this.db.license_history(this.license.id)
  }

  replaceLineBreaks(str: string): string {
    return str.replaceAll('\n', '<br>')
  }

  async editItem(history: data.LicenseHistory): Promise<void> {
    const dlgEditLicenseHistory = this.$refs.dlgEditLicenseHistory as DlgLicenseHistory
    if (await dlgEditLicenseHistory.edit(history.id)) {
      this.onLoad()
    }
  }
}

</script>

