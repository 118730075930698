import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dlg-obj',{ref:"theDialog",attrs:{"typeName":"Event Participant","defaultWidth":550,"getCurrentObject":_vm.getCurrentObject,"saveObject":_vm.saveObject,"isValid":_vm.isValid},on:{"focus":_vm.onFocus,"object":_vm.onObjectSet},scopedSlots:_vm._u([{key:"default",fn:function({obj}){return [_c(VContainer,[_c(VRow,[_c(VCol,[_c(VAutocomplete,{ref:"selectContact",attrs:{"label":"Contact","items":_vm.contacts,"clearable":"","hide-details":""},model:{value:(obj.contact_id),callback:function ($$v) {_vm.$set(obj, "contact_id", $$v)},expression:"obj.contact_id"}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextarea,{attrs:{"label":"Notes","hide-details":""},model:{value:(obj.notes),callback:function ($$v) {_vm.$set(obj, "notes", $$v)},expression:"obj.notes"}})],1)],1)],1),_c('dlg-contact',{ref:"dlgEditContact",attrs:{"db":_vm.db}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }