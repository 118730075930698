<template>

  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="dateStr"
        @input="updateDate"
        :label="label"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
        hide-details
        :style="myStyle"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="dateStr"
      @input="updateDate"
    >
      <v-text-field
        :value="timeStr"
        @input="updateTime"
        label="Time UTC (hh:mm)"
        hide-details
        style="max-width:7em"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="onClosePicker"
      >
        Close
      </v-btn>
    </v-date-picker>
  </v-menu>

</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EditDate extends Vue {

  @Prop(String) value!: string | null
  @Prop(String) label!: string
  @Prop(String) width!: string

  menu = false

  get dateStr(): string {
    const v = this.value
    if (v === null || v === undefined) { return '' }
    const idx = v.indexOf('T')
    if (idx > -1) {
      return v.substr(0, idx)
    }
    return v
  }

  get timeStr(): string {
    const v = this.value
    if (v === null || v === undefined || v.indexOf('T') === -1) { return '00:00' }
    const idx = v.indexOf('T')
    const time = v.substr(idx + 1, 8)
    if (time.length === 8 && time.endsWith(':00')) {
      return time.substring(0, 5)
    }
    return time
  }

  // eslint-disable-next-line
  get myStyle(): any {
    return {
      width: this.width
    }
  }

  updateDate(originalValue: string): void {
    const date = originalValue.trim()
    if (date === '') {
      this.$emit('input', null)
    }
    else {
      this.$emit('input', date + 'T' + this.timeStr)
    }
  }

  updateTime(originalValue: string): void {
    const time = originalValue.trim()
    if (time === '') {
      this.$emit('input', this.dateStr)
    }
    else {
      this.$emit('input', this.dateStr + 'T' + time)
    }
  }

  onClosePicker(): void {
    this.menu = false
  }
}

</script>
