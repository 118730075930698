import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({style:(_vm.myStyle),attrs:{"value":_vm.dateStr,"label":_vm.label,"prepend-icon":"mdi-calendar","hide-details":""},on:{"input":_vm.updateDate}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"value":_vm.dateStr},on:{"input":_vm.updateDate}},[_c(VTextField,{staticStyle:{"max-width":"7em"},attrs:{"value":_vm.timeStr,"label":"Time UTC (hh:mm)","hide-details":""},on:{"input":_vm.updateTime}}),_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.onClosePicker}},[_vm._v(" Close ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }