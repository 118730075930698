
import axios from 'axios'
import { AxiosInstance, AxiosRequestConfig } from 'axios'

export function getConnectionDB(token: string): AxiosInstance {

  const url = (process.env.NODE_ENV === 'development') ?
    'http://localhost:3000/rpc/' : '/rpc/'

  return axios.create({
    baseURL: url,
    timeout: 5000,
    headers: {
      'Authorization': 'Bearer ' + token
    },
  })
}

export function getConnectionBackend(token?: string): AxiosInstance {

  const url = (process.env.NODE_ENV === 'development') ?
    'http://localhost:4000/backend/' : '/backend/'

  const headers = token ? { 'Authorization': 'Bearer ' + token } : undefined

  const config : AxiosRequestConfig = {
    baseURL: url,
    timeout: 5000,
    headers,
  }

  return axios.create(config)
}
