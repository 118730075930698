<template>
  <div @keydown.esc="onEscape">
    <v-data-table
      :no-data-text="noDataText"
      :headers="headers"
      :items="filteredItems"
      multi-sort
      item-key="id"
      :footer-props="footer"
      show-expand
      :expanded.sync="expanded"
      class="elevation-4 mt-2">

      <template v-slot:top>
        <v-container>
          <v-row>
            <v-col md="auto">

              <v-select
                v-model="showExpired"
                :items="expiredOptions"
                style="width:9em">
              </v-select>

            </v-col>
            <v-col>

              <v-text-field
                v-model="search"
                label="Search"
                clearable
                class="mx-4">
              </v-text-field>

            </v-col>

            <v-col cols="auto" class="mt-5 mr-3">
              <v-btn small text @click="dump()"><v-icon>cloud_download</v-icon></v-btn>
            </v-col>

            <v-col cols="auto" class="mt-5 mr-3">
              <v-btn small color="primary" @click="addItem()"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>

          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.lic_number`]="{ item }">
        <span class="clickable" @click="editItem(item)">{{ item.lic_number }}</span>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">

          <v-container>

            <v-row>
              <v-col cols="auto">
                <license-history v-if="isExpanded(item)" :db="db" :license="item"></license-history>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <p style="font-size: 1.2em;" v-html="replaceLineBreaks(item.notes)"></p>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="auto">
                <v-btn small color="primary" @click="onCreateLicenseFile(item.id)">
                  Create license file
                </v-btn>
              </v-col>
              <v-col>
                <v-btn small color="primary" @click="onCopyToClipboard(item.download_code)">
                  {{ 'Copy download code' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

        </td>
      </template>

    </v-data-table>

    <dlg-license ref="dlgEditLicense" :db="db"></dlg-license>

  </div>
</template>

<script lang="ts">

import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import * as data from './../types'
import { DbVendor } from '../DbVendor'
import LicenseHistory from './LicenseHistory.vue'
import DlgLicense from '../dialogs/DlgLicense.vue'

@Component({
  components: {
    LicenseHistory,
    DlgLicense,
  },
})
export default class TableLicense extends Vue {

  @Prop(Object) db!: DbVendor

  search = ''
  noDataText = 'No licenses'
  footer = {
    showFirstLastPage: true,
    itemsPerPageOptions: [15, 50, 100, { text: 'All', value: -1 }],
  }
  headers = [
    { text: 'Number',   align: 'left',  sortable: true, value: 'lic_number', sort: this.sortLicNum  },
    { text: 'App',      align: 'left',  sortable: true, value: 'app'         },
    { text: 'Ver',      align: 'left',  sortable: true, value: 'ver'         },
    { text: 'Customer', align: 'left',  sortable: true, value: 'customer'    },
    { text: 'Contact',  align: 'left',  sortable: true, value: 'contact'     },
    { text: 'Type',     align: 'left',  sortable: true, value: 'lic_type'    },
    { text: 'Features', align: 'left',  sortable: true, value: 'features'    },
    { text: 'Academic', align: 'left',  sortable: true, value: 'academic'    },
    { text: 'Lock',     align: 'left',  sortable: true, value: 'protection'  },
    { text: 'Created',  align: 'left',  sortable: true, value: 'created_at'  },
    { text: 'Updates',  align: 'left',  sortable: true, value: 'updates_until'  },
    { text: 'Valid',    align: 'left',  sortable: true, value: 'valid_until' },
    { text: '',                                         value: 'data-table-expand' },
  ]
  expiredOptions = [
    { value: 0, text: 'Show All' },
    { value: 1, text: 'Show Expired' },
    { value: 2, text: 'Hide Expired' }
  ]
  showExpired = 0
  expanded: data.LicenseRow[] = []

  urlSelect = false

  mounted(): void {
    const params = (new URL(window.location.toString())).searchParams;
    const tab = params.get('tab')
    const search = params.get('search')
    if (tab === 'licenses' && search !== null) {
      this.search = search
      this.urlSelect = true
    }
  }

  @Watch('filteredItems')
  watch_filteredItems(): void {
    const items = this.filteredItems
    if (items.length === 1 && this.urlSelect) {
      this.expanded = [items[0]]
      this.urlSelect = false
    }
  }
  
  get filteredItems(): data.LicenseRow[] {
    const Now = new Date()
    if (this.showExpired === 1) return this.filteredItems0.filter((lic) => Now > new Date(lic.valid_until))
    if (this.showExpired === 2) return this.filteredItems0.filter((lic) => Now < new Date(lic.valid_until))
    return this.filteredItems0
  }

  get filteredItems0(): data.LicenseRow[] {

    const search = (this.search || '').toLowerCase()
    const words = search.split(' ').filter((w) => w !== '')
    const posWords = words.filter((w) => !w.startsWith('-'))
    const negWords = words.filter((w) => w.startsWith('-')).map((w) => w.substring(1)).filter((s) => s.length > 0)

    return this.db.licenses.filter((item) => {
      const valLower = (item.lic_number + ' ' +
                      item.app + ' ' +
                      item.ver + ' ' +
                      item.customer + ' ' +
                      item.contact + ' ' +
                      item.lic_type + ' ' +
                      item.academic + ' ' +
                      item.download_code + ' ' +
                      item.protection).toLowerCase()
      return posWords.every((word) => valLower.indexOf(word) !== -1) &&
             negWords.every((word) => valLower.indexOf(word) === -1)
    })
  }

  isExpanded(evt: data.LicenseRow): boolean {
    return this.expanded.findIndex((e) => e.id === evt.id) > -1
  }

  replaceLineBreaks(str: string): string {
    return str.replaceAll('\n', '<br>')
  }

  onEscape(): void {
    this.expanded.splice(0)
  }

  async addItem(): Promise<void> {
    const dlgEditLicense = this.$refs.dlgEditLicense as DlgLicense
    await dlgEditLicense.add_new()
  }

  async editItem(license: data.LicenseRow): Promise<void> {
    const dlgEditLicense = this.$refs.dlgEditLicense as DlgLicense
    await dlgEditLicense.edit(license.id)
  }

  sortLicNum(strA: string, strB: string): number {
    let a = Number.parseInt(strA)
    let b = Number.parseInt(strB)

    if (Number.isNaN(a)) {
      a = 0
    }

    if (Number.isNaN(b)) {
      b = 0
    }

    if (a === b) { return strA.localeCompare(strB) }
    return a - b
  }

  async onCreateLicenseFile(license_id: number): Promise<void> {
    const lic = await this.db.createLicenseFile(license_id)
    if (lic !== null) {
      const blob = new Blob([lic.content], { type: 'application/xml' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = lic.file
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  onCopyToClipboard(txt: string): void {
    navigator.clipboard.writeText(txt);
    alert('Copied ' + txt + ' to clipboard.')
  }

  async dump(): Promise<void> {
    await this.db.dumpLicenses()
  }
}

</script>

