<template>

  <dlg-obj typeName="Event"
           :defaultWidth="550"
           :getCurrentObject="getCurrentObject"
           :saveObject="saveObject"
           :isValid="isValid"
           v-slot="{obj}"
           @focus="onFocus"
           ref="theDialog">

    <v-container>

      <v-row>
        <v-col>
          <v-text-field label="Event name"      v-model="obj.event_name" ref="txtFocusStart" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <edit-date    label="Event date"      v-model="obj.event_date"></edit-date>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field label="Event Location"  v-model="obj.event_location" hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea   label="Notes"           v-model="obj.notes" hide-details></v-textarea>
        </v-col>
      </v-row>

    </v-container>

  </dlg-obj>

</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator'
import { DbVendor } from '../DbVendor'
import DlgObj from './DlgObj.vue'
import EditDate from '../components/EditDate.vue'
import * as data from './../types'

@Component({
  components: {
    DlgObj,
    EditDate,
  },
})
export default class DlgEvent extends Vue {

  @Prop(Object) db!: DbVendor

  isValid(obj: data.Event): boolean {
    return obj.event_name !== ''
  }

  getCurrentObject(id: number): Promise<data.Event> {
    return this.db.event(id)
  }

  saveObject(obj: data.Event, is_new: boolean): Promise<number | null> {
    return this.db.event_write(obj, is_new)
  }

  onFocus(): void {
    const element = this.$refs.txtFocusStart as HTMLElement
    if (element) {
      element.focus()
    }
  }

  add_new(): Promise<number | null> {
    const dlg = this.$refs.theDialog as DlgObj
    const newObj = data.getDefaultEvent()
    return dlg.add_new(newObj)
  }

  edit(id: number): Promise<boolean> {
    const dlg = this.$refs.theDialog as DlgObj
    return dlg.edit(id, data.getDefaultEvent())
  }
}

</script>
