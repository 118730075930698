<template>
  <div @keydown.esc="onEscape">
    <v-data-table
      :no-data-text="noDataText"
      :headers="headers"
      :items="filteredItems"
      item-key="id"
      :footer-props="footer"
      show-expand
      :expanded.sync="expanded"
      class="elevation-4 mt-2">

      <template v-slot:top>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field v-model="search" label="Search" clearable class="mx-4"></v-text-field>
            </v-col>
            <v-col cols="auto" class="mt-5 mr-3">
              <v-btn small text @click="dump()"><v-icon>cloud_download</v-icon></v-btn>
            </v-col>
            <v-col cols="auto" class="mt-5 mr-3">
              <v-btn small text @click="showChristmasList()"><v-icon>card_giftcard</v-icon></v-btn>
            </v-col>
            <v-col cols="auto" class="mt-5 mr-3">
              <v-btn small color="primary" @click="addItem()"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.last_name`]="{ item }">
        <span :class="{ retired: item.retired }" class="clickable" @click="editItem(item)">{{ item.last_name }}</span>
      </template>

      <template v-slot:[`item.first_name`]="{ item }">
        <span :class="{ retired: item.retired }" class="clickable" @click="editItem(item)">{{ item.first_name }}</span>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <span :class="{ retired: item.retired }">{{ item.email }}</span>
      </template>

      <template v-slot:[`item.mailing`]="{ item }">
        {{ formatMailingState(item.mailing) }}
      </template>

      <template v-slot:[`item.retired`]="{ item }">
        {{ formatRetired(item.retired) }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">

          <v-container>

            <v-row>
              <v-col cols="auto">
                <related-licenses   v-if="isExpanded(item)" :db="db" :contact="item" :hideCustomer="true" :hideContact="true"></related-licenses>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <related-activities v-if="isExpanded(item)" :db="db" :contact="item" :hideCustomer="true" :hideContact="true"></related-activities>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <related-events     v-if="isExpanded(item)" :db="db" :contact="item"></related-events>
              </v-col>
            </v-row>

          </v-container>

        </td>
      </template>

    </v-data-table>
    <dlg-contact ref="dlgEditContact" :db="db"></dlg-contact>
    <dlg-christmas ref="dlgChristmas" :db="db"></dlg-christmas>
  </div>
</template>

<script lang="ts">

import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import * as data from './../types'
import RelatedLicenses from './RelatedLicenses.vue'
import RelatedActivities from './RelatedActivities.vue'
import RelatedEvents from './RelatedEvents.vue'
import { DbVendor } from '../DbVendor'
import DlgContact from '../dialogs/DlgContact.vue'
import DlgChristmas from '../dialogs/DlgChristmas.vue'

@Component({
  components: {
    DlgContact,
    RelatedLicenses,
    RelatedActivities,
    RelatedEvents,
    DlgChristmas,
  },
})
export default class TableContact extends Vue {

  @Prop(Object) db!: DbVendor

  search = ''
  noDataText = 'No contacts'
  footer = {
    showFirstLastPage: true,
    itemsPerPageOptions: [15, 50, 100, { text: 'All', value: -1 }],
  }
  headers = [
    { text: 'Last Name',  align: 'left',  sortable: true, value: 'last_name' },
    { text: 'First Name', align: 'left',  sortable: true, value: 'first_name' },
    { text: 'Customer',   align: 'left',  sortable: true, value: 'customer' },
    { text: 'E-Mail',     align: 'left',  sortable: true, value: 'email' },
    { text: 'Retired',    align: 'left',  sortable: true, value: 'retired' },
    { text: 'Allow Mail', align: 'left',  sortable: true, value: 'mailing' },
    { text: 'Phone',      align: 'left',  sortable: true, value: 'phone' },
    { text: 'Licenses',   align: 'right', sortable: true, value: 'license_count' },
    { text: '',                                           value: 'data-table-expand' },
  ]
  expanded: data.ContactRow[] = []

  urlSelect = false 

  mounted(): void {
    const params = (new URL(window.location.toString())).searchParams;
    const tab = params.get('tab')
    const search = params.get('search')
    if (tab === 'contacts' && search !== null) {
      this.search = search
      this.urlSelect = true
    }
  }

  @Watch('filteredItems')
  watch_filteredItems(): void {
    const items = this.filteredItems
    if (items.length === 1 && this.urlSelect) {
      this.expanded = [items[0]]
      this.urlSelect = false
    }
  }

  get filteredItems(): data.ContactRow[] {

    const search = (this.search || '').toLowerCase()
    const words = search.split(' ').filter((w) => w !== '')
    const posWords = words.filter((w) => !w.startsWith('-'))
    const negWords = words.filter((w) => w.startsWith('-')).map((w) => w.substring(1)).filter((s) => s.length > 0)

    return this.db.contacts.filter((item) => {
      const valLower = (item.last_name + ' ' +
                        item.first_name + ' ' +
                        item.customer + ' ' +
                        item.email + ' ' +
                        this.formatMailingState(item.mailing) + ' ' +
                        this.formatRetired(item.retired) + ' ' +
                        item.phone).toLowerCase()
      return posWords.every((word) => valLower.indexOf(word) !== -1) &&
             negWords.every((word) => valLower.indexOf(word) === -1)
    })
  }

  formatMailingState(state: string | null): string {
    if (state === null) { return 'Unknown' }
    if (state.indexOf('Agreed') >= 0) { return 'Agreed' }
    if (state.indexOf('Refused') >= 0) { return 'Refused' }
    return '???'
  }

  formatRetired(retired: boolean): string {
    if (retired) { return 'Retired' }
    return ''
  }

  isExpanded(contact: data.ContactRow): boolean {
    return this.expanded.findIndex((c) => c.id === contact.id) > -1
  }

  onEscape(): void {
    this.expanded.splice(0)
  }

  async addItem(): Promise<void> {
    const dlgEditContact = this.$refs.dlgEditContact as DlgContact
    await dlgEditContact.add_new()
  }

  async editItem(contact: data.ContactRow): Promise<void> {
    const dlgEditContact = this.$refs.dlgEditContact as DlgContact
    await dlgEditContact.edit(contact.id)
  }

  async dump(): Promise<void> {
    await this.db.dumpContacts()
  }

  async dumpChristmas(): Promise<void> {
    await this.db.dumpContactsChristmas()
  }

  showChristmasList(): void {
    const dlgChristmas = this.$refs.dlgChristmas as DlgChristmas
    dlgChristmas.show()
  }

}

</script>

