import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.Items,"no-data-text":"No license history","item-key":"id","dense":"","hide-default-footer":"","footer-props":_vm.footer,"show-expand":"","expanded":_vm.expanded,"item-class":() => 'clickable'},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.editItem},scopedSlots:_vm._u([{key:`item.icon`,fn:function(){return [_c(VIcon,[_vm._v("history")])]},proxy:true},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"auto"}},[_c('p',{staticStyle:{"font-size":"1.0em"},domProps:{"innerHTML":_vm._s(_vm.replaceLineBreaks(item.notes))}})])],1)],1)],1)]}}],null,true)}),_c('dlg-license-history',{ref:"dlgEditLicenseHistory",attrs:{"db":_vm.db}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }