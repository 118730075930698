<template>
  <v-app light>
    <v-main>
      <v-container>

        <v-select
          v-if="vendors.length > 1"
          label="Vendor"
          v-model="vendor"
          :items="vendors"
          item-text="name"
          item-value="id"></v-select>

        <v-app-bar
          dense>
          <v-btn :color="selectedTab == 'tickets'   ? 'blue' : ''" text @click="onSelectTickets"><v-icon>history</v-icon>Tickets</v-btn>
          <v-btn :color="selectedTab == 'customers' ? 'blue' : ''" text @click="onSelectCustomers"><v-icon>domain</v-icon>Customer</v-btn>
          <v-btn :color="selectedTab == 'contacts'  ? 'blue' : ''" text @click="onSelectContacts"><v-icon>person</v-icon>Contacts</v-btn>
          <v-btn :color="selectedTab == 'licenses'  ? 'blue' : ''" text @click="onSelectLicenses"><v-icon>security</v-icon>Licenses</v-btn>
          <v-btn :color="selectedTab == 'events'    ? 'blue' : ''" text @click="onSelectEvents"><v-icon>event</v-icon>Events</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="showChangeLog"> <v-icon>history</v-icon></v-btn>
          <v-btn text @click="switchDarkMode"><v-icon>mdi-theme-light-dark</v-icon></v-btn>
          <v-btn text @click="logout"><v-icon>logout</v-icon></v-btn>
        </v-app-bar>

        <table-activity v-show="selectedTab=='tickets'"   :db="db"></table-activity>
        <table-customer v-show="selectedTab=='customers'" :db="db"></table-customer>
        <table-contact  v-show="selectedTab=='contacts'"  :db="db"></table-contact>
        <table-license  v-show="selectedTab=='licenses'"  :db="db"></table-license>
        <table-event    v-show="selectedTab=='events'"    :db="db"></table-event>

        <dlg-change-log ref="dlgChangeLog" :db="db"></dlg-change-log>

      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">

import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import TableActivity from './components/TableActivity.vue'
import TableCustomer from './components/TableCustomer.vue'
import TableContact from './components/TableContact.vue'
import TableLicense from './components/TableLicense.vue'
import TableEvent from './components/TableEvent.vue'
import DlgChangeLog from './dialogs/DlgChangeLog.vue'
import * as data from './types'
import { DbVendor } from './DbVendor'

type Tab = 'tickets' | 'customers' | 'contacts' | 'licenses' | 'events'

@Component({
  components: {
    TableActivity,
    TableCustomer,
    TableContact,
    TableLicense,
    TableEvent,
    DlgChangeLog,
  },
})
export default class AppVendor extends Vue {

  @Prop({ default() { return '' } }) token!: string
  @Prop({ default() { return '' } }) email!: string
  @Prop({ default() { return [] } }) vendors!: data.IdName[]

  selectedTab: Tab = 'tickets'
  vendor = -1
  db: DbVendor = new DbVendor()

  mounted(): void {
    if (this.vendors.length > 0) {
      this.vendor = this.vendors[0].id
      this.db = new DbVendor(this.vendor, this.token, this.logout)
    }
    this.selectedTab = this.selectedTabFromUrl() || 'tickets'
  }

  selectedTabFromUrl(): Tab | null {
    let params = (new URL(window.location.toString())).searchParams;
    const tab = params.get('tab')    
    if (tab === null) { return null }
    switch (tab) {
      case 'tickets': return 'tickets'
      case 'customers': return 'customers'
      case 'contacts': return 'contacts'
      case 'licenses': return 'licenses'
      case 'events': return 'events'
      default: return null
    }
  }

  @Watch('selectedTab')
  watch_selectedTab(): void {
    const tab = this.selectedTabFromUrl()
    if (tab !== null && this.selectedTab !== tab) {
      const newURL = new URL(window.location.toString())
      newURL.search = ''
      window.history.replaceState({}, '', newURL.toString())
    }
  }

  @Watch('vendor')
  watch_VendorChanged(): void {
    if (this.db === null || this.db.the_vendor !== this.vendor) {
      this.db = new DbVendor(this.vendor, this.token, this.logout)
    }
  }

  onSelectTickets(): void {
    this.selectedTab = 'tickets'
    this.db.refreshActivities()
  }

  onSelectCustomers(): void {
    this.selectedTab = 'customers'
    this.db.refreshCustomers()
  }

  onSelectContacts(): void {
    this.selectedTab = 'contacts'
    this.db.refreshContacts()
  }

  onSelectLicenses(): void {
    this.selectedTab = 'licenses'
    this.db.refreshLicenses()
  }

  onSelectEvents(): void {
    this.selectedTab = 'events'
    this.db.refreshEvents()
  }

  switchDarkMode(): void {
    const dark = !this.$vuetify.theme.dark
    this.$vuetify.theme.dark = dark
    window.localStorage.setItem('is_dark_mode', dark.toString())
  }

  showChangeLog(): void {
    const dlgChangeLog = this.$refs.dlgChangeLog as DlgChangeLog
    dlgChangeLog.show()
  }

  logout(): void {
    this.$emit('logout')
  }
}

</script>
