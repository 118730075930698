
export interface LicenseFile {
  file: string
  content: string
}

export interface EnumValue {
  enum: string
  value: string
}

export interface VendorLogin {
  id: number
  login: string
  email: string
  can_login: boolean
  full_name: string
  vendor_id: number
  vendor_name: string
}

export interface App {
  id: number
  short_name: string
}

export interface AppVersion {
  id: number
  app_id: number
  ver: string
  release_date: string
}

export interface LicenseRow {
  id: number
  lic_number: string
  app: string
  app_id: number
  ver: string | null
  customer_id: number
  customer: string
  contact_id: number
  contact: string
  lic_type: string
  features: string
  academic: string
  protection: string
  created_at: string
  valid_until: string
  updates_until: string
  notes: string
  download_code: string
}

export interface License {
  id: number

  app_id: number
  app_version_id: number | null
  lic_number: string

  customer_id: number | null
  contact_id: number | null

  created_at: string
  last_modified: string
  updates_until: string
  valid_until: string

  usage_type: string
  lic_type: string
  protection: string
  academic: string

  features: string

  remark: string
  notes: string

  disabled: boolean
  disabled_note: string

  hardware_id: string
  seats: number
  remote_only: boolean
  servers: string
}

const DefaultLicense: License = {
  id: 0,
  app_id: 1, // 1 = SIMBA
  app_version_id: null,
  lic_number: '',
  usage_type: 'Standard',
  customer_id: null,
  contact_id: null,
  created_at: '',
  last_modified: '',
  lic_type: '',
  protection: '',
  academic: '',
  features: 'W',
  updates_until: '',
  valid_until: '',
  remark: '',
  notes: '',
  disabled: false,
  disabled_note: '',
  hardware_id: '',
  seats: 0,
  remote_only: false,
  servers: '',
}

export function getDefaultLicense(): License {
  const lic: License = JSON.parse(JSON.stringify(DefaultLicense))

  const now = new Date()

  lic.created_at = now.toISOString()
  lic.last_modified = now.toISOString()

  const YearMillliseconds = 368 * 24 * 60 * 60 * 1000
  const nextYear = new Date(now.getTime() + YearMillliseconds)

  lic.valid_until = nextYear.toISOString()
  lic.updates_until = nextYear.toISOString()

  return lic
}

export interface LicenseHistory {
  id: number
  lic_number: string
  change_date: string
  change_actor: string | null
  change_comment: string
  app_version: string | null
  customer_id: number
  customer: string
  contact_id: number
  contact: string
  lic_type: string
  features: string
  academic: string
  protection: string
  valid_until: string
  updates_until: string
  disabled: boolean
  seats: number
  hardware: string
  remark: string
  notes: string
  servers: string
}

export interface LicenseHistoryMeta {
  id: number
  change_date: string
  change_actor_id: number | null
  change_comment: string
}

export interface CustomerRow {
  id: number
  type_customer: string | null
  domain_name: string
  short_name: string
  full_name: string
  full_name_ext1: string
  full_name_ext2: string
  full_name_ext3: string
  www: string
  lang: string,
  license_count: number
}

export interface Customer {
  id: number
  type_customer: string | null
  domain_name: string
  short_name: string
  full_name: string
  full_name_ext1: string
  full_name_ext2: string
  full_name_ext3: string
  www: string
  lang: string
  notes: string
}

const DefaultCustomer: Customer = {
  id: 0,
  type_customer: 'Company',
  domain_name: '',
  short_name: '',
  full_name: '',
  full_name_ext1: '',
  full_name_ext2: '',
  full_name_ext3: '',
  www: '',
  lang: '',
  notes: '',
}

export function getDefaultCustomer(): Customer {
  return JSON.parse(JSON.stringify(DefaultCustomer))
}

export interface AddressRow {
  id: number
  street1: string
  street2: string
  postal_code: string
  town: string
  country: string
  addr_type: string
  count: number
}

export interface Address {
  id: number
  customer_id: number
  street1: string
  street2: string
  country: string
  territory: string
  town: string
  postal_code: string
  addr_type: string
  notes: string
}

const DefaultAddress: Address = {
  id: 0,
  customer_id: 0,
  street1: '',
  street2: '',
  country: '',
  territory: '',
  town: '',
  postal_code: '',
  addr_type: 'HeadquaterGeographic',
  notes: '',
}

export function getDefaultAddress(): Address {
  return JSON.parse(JSON.stringify(DefaultAddress))
}

export interface ContactRow {
  id: number
  gender: string
  title: string
  first_name: string
  last_name: string
  customer_id: number
  customer: string
  lang: string
  email: string
  mailing: string
  phone: string
  mobile: string
  retired: boolean
  license_count: number
  street1: string
  street2: string
  postal: string
  town: string
  country: string
}

export interface Contact {
  id: number
  customer_id: number | null
  gender: string
  title: string
  first_name: string
  last_name: string
  contact_type: string | null
  mailing_state: string | null
  mailing_notes: string
  retired: boolean
  phone: string
  mobile: string
  email: string
  address_id: number | null
  lang: string
  notes: string
}

const DefaultContact: Contact = {
  id: 0,
  customer_id: 0,
  gender: 'Male',
  title: '',
  first_name: '',
  last_name: '',
  contact_type: null,
  mailing_state: null,
  mailing_notes: '',
  retired: false,
  phone: '',
  mobile: '',
  email: '',
  address_id: null,
  lang: '',
  notes: '',
}

export function getDefaultContact(): Contact {
  return JSON.parse(JSON.stringify(DefaultContact))
}

export interface EventRow {
  id: number
  event_name: string
  event_date: string
  event_location: string
  event_participants: number
}

export interface Event {
  id: number
  event_name: string
  event_date: string
  event_location: string
  notes: string
}

const DefaultEvent: Event = {
  id: 0,
  event_name: '',
  event_date: '',
  event_location: '',
  notes: '',
}

export function getDefaultEvent(): Event {
  const event = JSON.parse(JSON.stringify(DefaultEvent))
  const now = new Date()
  event.event_date = now.toISOString()
  return event
}

export interface EventParticipantRow {
  id: number
  event_id: number
  event_name: string
  contact_id: number
  gender: string
  title: string
  last_name: string
  first_name: string
  lang: string
  email: string
  phone: string
  mobile: string
  retired: boolean
  customer: string
  notes: string
  mailing: string
  street: string
  postal: string
  town: string
  country: string
}

export interface EventParticipant {
  id: number
  event_id: number
  contact_id: number | null
  notes: string
}

const DefaultEventParticipant: EventParticipant = {
  id: 0,
  event_id: 0,
  contact_id: null,
  notes: '',
}

export function getDefaultEventParticipant(): EventParticipant {
  return JSON.parse(JSON.stringify(DefaultEventParticipant))
}

export interface ActivityRow {
  id: number
  subject: string
  assigned_to_id: number
  assigned_to: string
  customer_id: number | null
  customer: string | null
  contact_id: number | null
  contact: string
  created_at: string
  due_at: string | null
  last_updated: string
  priority: string
  status: Status
}

export interface Activity {
  id: number
  subject: string
  assigned_to_id: number
  customer_id: number | null
  contact_id: number | null
  created_at: string
  due_at: string | null
  activity_type: string
  priority: string
  status: Status
  notes: string
}

const DefaultActivity: Activity = {
  id: 0,
  subject: '',
  assigned_to_id: 0,
  customer_id: null,
  contact_id: null,
  created_at: '',
  due_at: null,
  activity_type: 'Ticket',
  priority: 'Normal',
  status: 'ActionRequired',
  notes: '',
}

export function getDefaultActivity(): Activity {
  const res: Activity = JSON.parse(JSON.stringify(DefaultActivity))
  const now = new Date()
  res.created_at = now.toISOString()
  return res
}

export function activity_IsDue(activity: ActivityRow): boolean {
  if (activity.due_at === null) { return false }
  const due = new Date(activity.due_at)
  const now = new Date()
  return due <= now
}

export type Status = 'ActionRequired' | 'Wait' | 'Closed'

export interface ActivityItemRow {
  id: number
  actor: string | null
  actor_id: number
  date: string
  notes: string
}

export interface ActivityItem {
  id: number
  activity_id: number
  actor_id: number
  date: string
  notes: string
}

const DefaultActivityItem: ActivityItem = {
  id: 0,
  activity_id: 0,
  actor_id: 0,
  date: '',
  notes: '',
}

export function getDefaultActivityItem(): ActivityItem {
  const res: ActivityItem = JSON.parse(JSON.stringify(DefaultActivityItem))
  const now = new Date()
  res.date = now.toISOString()
  return res
}

export interface IdName {
  id: number,
  name: string
}

export function mapMailing2Bool(mailing: string | null): string | boolean {
  if (mailing === null) { return '' }
  if (mailing.indexOf('Agreed') >= 0) { return true }
  if (mailing.indexOf('Refused') >= 0) { return false }
  return '???' 
}

export interface ChangeLogEntry {
  date: string
  who: string
  change_type: string
  entity: string
  entity_id: number
  info_text: string
  changes: string
  row_values: string
}

export interface ChristmasListEntry {
  num: number
  id: number
  date_last: string
  first_name: string
  last_name: string
  mailing: string
  customer_id: number
  customer: string
  customer_full: string
  customer_full_ext1: string
  customer_full_ext2: string
  customer_full_ext3: string
  email: string
  street1: string
  street2: string
  postal: string 
  town: string
  country: string
}